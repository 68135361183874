/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import resources from '../../../components/api/resources/resources';

import template from './list-column-selector-component.html';

const MODULE_NAME = "list-column-selector";

angular.module(MODULE_NAME, [
  resources
])

  .component('adnListColumnSelector', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      allFields: '<',
      defaultFields: '<',
      sel: '<',
      changeHook: '<',
      profileParam: '@'
    },
    controller: function($timeout, $log, ChartOptionsUtil, UserProfile, localUserProfile) {
      const ctrl = this;

      let localUser = localUserProfile.get();

      ctrl.$onInit = function() {
        if (!ctrl.sel.selFields || !ctrl.sel.outputFields) {
          ctrl.sel.outputFields = _.cloneDeep(ctrl.defaultFields);
          ctrl.sel.selFields = _.map(ctrl.defaultFields, function(l) {
            return l.param || l.label || l;
          });
        }
        const fieldsUpdated = function() {
          if (_.isFunction(_.get(ctrl, ['changeHook', 'fieldChange']))) {
            ctrl.changeHook.fieldChange();
          }
        };

        let profile = localUserProfile.get().profile;
        const profileSelFieldIds = _.get(profile[ctrl.profileParam], 'selFieldIds') || [];
        if (profileSelFieldIds.length > 0) {
          const verifiedSelFields = _.filter(ctrl.allFields, function(l) {
            return profileSelFieldIds.indexOf(l.param || l.label || l) > -1;
          });
          ctrl.sel.selFields = _.map(verifiedSelFields, function(l) {
            return l.param || l.label || l;
          });
          ctrl.sel.outputFields = verifiedSelFields;
          fieldsUpdated();
        }

        ctrl.updateFields = function() {
          $timeout(function() {
            ctrl.sel.outputFields = _.filter(ctrl.allFields, function(l) {
              return ctrl.sel.selFields.indexOf(l.param || l.label || l) > -1;
            });
            fieldsUpdated();

            if (_.isString(ctrl.profileParam)) {
              profile[ctrl.profileParam] = {
                selFieldIds: ctrl.sel.selFields,
              };
              UserProfile.save({
                id: localUser.userId,
                profile: _.pick(profile, ctrl.profileParam)
              }, function(apiUser) {
                localUser = localUserProfile.safeSaveProfile(ctrl.profileParam, apiUser.profile[ctrl.profileParam]);
                profile = localUser.profile;
              });
            } else {
              $log.warn("Can't save settings because of a faulty profileParam of " + ctrl.profileParam);
            }
          }, 101);
        };
      };
    }
  });

export default MODULE_NAME;