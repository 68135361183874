/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';

import standardFormModule from '../../common/standard-form-module';
import objMembershipCtrl from "./obj-membership-ctrl";
import {adnFormHelper} from "../../common/controller/form-helper";
import objTemplate from "./obj-membership.html";
import ownershipTemplate from "./ownership.html";
import {MoneyHelper} from "../../../components/util/money";
import {LocalNetworkInfo} from "../../../components/session/local-network-info";
import {ADN_TARGETING_TYPES} from "../../targeting/targeting-constants";
import targetingTemplate from "../../targeting/base-targeting-editor-modal.html";

const MODULE_NAME = 'team-controller';

angular.module(MODULE_NAME, [standardFormModule, objMembershipCtrl, uiBootstrap])

  .controller('TeamCtrl', function(Site, Folder, adnModalLoading, TeamGroup, LocalUserPermissions, $stateParams, $uibModal, $timeout, LocalNetworkProfile, model, modelComms) {
    const ctrl = this;
    ctrl.applications = LocalUserPermissions.getApps();
    ctrl.canSeeTechFees = LocalUserPermissions.hasNetwork("MANAGE_SYSTEM");
    ctrl.canSeeTeamGroups = LocalUserPermissions.hasNetwork("MANAGE_SYSTEM") && LocalNetworkInfo.obtain().getInfo("teamGroupsCount") > 0;
    ctrl.canSeeSiteLinks = LocalUserPermissions.hasNetwork("MANAGE_SYSTEM") || LocalUserPermissions.hasNetwork("MANAGE_PUBLISHING") || LocalUserPermissions.hasNetwork("AX_PUBLISHER_ADMIN");
    ctrl.isNew = $stateParams.isNew;

    ctrl.getNetworkTranslation = function(key) {
      return LocalNetworkProfile.getNetworkTranslations(key);
    };

    const defaultCurrency = LocalNetworkProfile.get('defaultCurrency');
    ctrl.networkCurrencies = _.orderBy(LocalNetworkProfile.get('availableCurrencies'), function(curr) {
      return curr === defaultCurrency ? 0 : 1;
    });

    ctrl.targetTypes = _.filter(ADN_TARGETING_TYPES, function(tt) {
      return tt.apiType !== 'THIRD_PARTY_AUDIENCE';
    });

    function initFeesForParam(ref) {
      _.forEach(ctrl.networkCurrencies, function(currency) {
        const foundFee = _.find(_.get(ref, 'multiCurrencyMonetaryAmounts', []), function(fee) {
          return fee.currency === currency;
        });
        if (!foundFee) {
          const monetary = _.get(ref, 'monetary');
          if (monetary && monetary.currency === currency) {
            ref.type = 'MULTI_CURRENCY_MONETARY';
            ref.multiCurrencyMonetaryAmounts = ref.multiCurrencyMonetaryAmounts || [];
            ref.multiCurrencyMonetaryAmounts.push(monetary);
          } else {
            ref.multiCurrencyMonetaryAmounts = ref.multiCurrencyMonetaryAmounts || [];
            ref.multiCurrencyMonetaryAmounts.push({currency: currency});
          }
        }
      });
      ref.multiCurrencyMonetaryAmounts = _.orderBy(ref.multiCurrencyMonetaryAmounts, function(fee) {
        return ctrl.networkCurrencies.indexOf(fee.currency);
      });
    }

    function initialiseLayoutFees() {
      let layoutFees = _.get(ctrl.model, 'layoutFees');
      ctrl.model.uiLayoutFees = _.isArray(layoutFees) ? layoutFees : [];
      ctrl.model.uiLayoutFees[0] = ctrl.model.uiLayoutFees[0] ? ctrl.model.uiLayoutFees[0] : {};
      ctrl.model.uiLayoutFees = _.map(ctrl.model.uiLayoutFees, function(layoutFee) {
        const adjFee = _.cloneDeep(layoutFee) || {};
        adjFee.fee = adjFee.fee || {type: 'MULTI_CURRENCY_MONETARY'};
        initFeesForParam(adjFee.fee);
        return adjFee;
      });
    }

    function initTargetTypeFees() {
      ctrl.model.uiTargetingTypeTechFees = _.get(ctrl.model, 'targetingTypeTechFees', []);
      ctrl.model.uiTargetingTypeTechFees = _.map(ctrl.model.uiTargetingTypeTechFees, function(valFee, key) {
        return {apiType: key, fee: valFee};
      });
      if (_.isEmpty(ctrl.model.uiTargetingTypeTechFees)) {
        ctrl.model.uiTargetingTypeTechFees = [];
      }
      if (!ctrl.model.uiTargetingTypeTechFees.length) {
        ctrl.model.uiTargetingTypeTechFees.push({fee: {type: 'MULTI_CURRENCY_MONETARY'}});
      }
      _.forEach(ctrl.model.uiTargetingTypeTechFees, function(fee) {
        initFeesForParam(fee.fee);
      });
    }

    let originalTeamGroupId;

    function teamGroupQuery() {
      const teamGroupsCount = LocalNetworkInfo.obtain().getInfo("teamGroupsCount");
      if (teamGroupsCount > 0) {
        if (ctrl.canSeeTeamGroups && !ctrl.allTeamGroups && !ctrl.isNew) {
          TeamGroup.query({filterBy: 'type', filterByValue: ctrl.model.type}).$promise.then(function(page) {
            ctrl.allTeamGroups = page.results;
            ctrl.model.teamGroup = _.find(ctrl.allTeamGroups, function(tg) {
              return _.find(tg.teams, function(t) {
                return ctrl.model.id === t.id;
              });
            }) || null;
            originalTeamGroupId = _.get(ctrl.model, ['teamGroup', 'id']);
          });
        }
      }
    }

    ctrl.addSupplementaryFee = function() {
      if (_.get(ctrl, ['editsMade'])) {
        ctrl.editsMade();
      }
      ctrl.model.supplementaryFees = ctrl.model.supplementaryFees || [];
      const supFee = {
        type: 'PERCENTAGE',
        name: 'Fee',
        amount: null
      };
      initFeesForParam(supFee);
      ctrl.model.supplementaryFees.unshift(supFee);
    };

    ctrl.deleteFee = function(index) {
      if (_.get(ctrl, ['editsMade'])) {
        ctrl.editsMade();
      }
      _.pullAt(ctrl.model.supplementaryFees, index);
      if (ctrl.model.supplementaryFees.length === 0) {
        ctrl.addSupplementaryFee();
      }
    };

    ctrl.removeLayoutFee = function(index) {
      _.pullAt(ctrl.model.uiLayoutFees, index);
      if (ctrl.model.uiLayoutFees.length === 0) {
        ctrl.addLayoutFee();
      }
      ctrl.editsMade();
    };
    ctrl.addLayoutFee = function() {
      ctrl.model.uiLayoutFees = ctrl.model.uiLayoutFees || [];
      const defaultFee = {fee: {type: 'MULTI_CURRENCY_MONETARY'}};
      initFeesForParam(defaultFee.fee);
      ctrl.model.uiLayoutFees.push(defaultFee);
      ctrl.editsMade();
    };

    ctrl.removeTargetingFee = function(index) {
      _.pullAt(ctrl.model.uiTargetingTypeTechFees, index);
      if (ctrl.model.uiTargetingTypeTechFees.length === 0) {
        ctrl.addTargetingFee();
      }
      ctrl.editsMade();
    };
    ctrl.addTargetingFee = function() {
      ctrl.model.uiTargetingTypeTechFees = ctrl.model.uiTargetingTypeTechFees || [];
      const defaultFee = {fee: {type: 'MULTI_CURRENCY_MONETARY'}};
      initFeesForParam(defaultFee.fee);
      ctrl.model.uiTargetingTypeTechFees.push(defaultFee);
      ctrl.editsMade();
    };

    function afterSaveAfterInit() {
      ctrl.model.type = ctrl.model.type || (LocalUserPermissions.hasNetworkPermission('AX_ADVERTISER_ADMIN') ? 'AX_ADVERTISER' : 'STANDARD');

      ctrl.model.techFeeCpm = ctrl.model.techFeeCpm || {type: 'PERCENTAGE'};
      ctrl.model.creativeTagTechFeeCpm = ctrl.model.creativeTagTechFeeCpm || {type: 'MULTI_CURRENCY_MONETARY'};
      initFeesForParam(ctrl.model.techFeeCpm);
      initFeesForParam(ctrl.model.creativeTagTechFeeCpm);

      teamGroupQuery();
      initialiseLayoutFees();
      initTargetTypeFees();

      ctrl.model.supplementaryFees = _.get(ctrl.model, 'supplementaryFees', []);
      _.forEach(ctrl.model.supplementaryFees, function(fee) {
        initFeesForParam(fee);
      });
    }

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterSave: function() {
        afterSaveAfterInit();
      },
      afterInit: function() {
        afterSaveAfterInit();
      }
    });

    let siteRefresh = function() {
      if (ctrl.applications.indexOf("ADVERTISING") > -1) {
        const params = {pageSize: 1000};
        Site.query(params, function(page) {
          ctrl.sites = _.map(page.results, function(sg) {
            let indexedTeams = _.keyBy(sg.teams, 'id');
            return _.assign({}, _.pick(sg, ['name', 'description', 'id', 'teams', 'ownerTeam']), {indexedTeams: indexedTeams, isMatched: !!indexedTeams[model.id], isOwned: !!_.get(sg.ownerTeam, ['id']), isOwner: _.get(sg.ownerTeam, ['id']) === ctrl.model.id});
          });

          ctrl.unownedSites = _.filter(ctrl.sites, {isOwned: false});
          ctrl.matchedSites = _.filter(ctrl.sites, {isMatched: true});
          ctrl.ownerSites = _.filter(ctrl.sites, {isOwner: true});
        });
      }
    };
    siteRefresh();

    let folderRefresh = function() {
      if (ctrl.applications.indexOf("DATA") > -1) {
        Folder.query({pageSize: 1000}, function(page) {
          ctrl.folders = _.map(page.results, function(sg) {
            let indexedTeams = _.keyBy(sg.teams, 'id');
            return _.assign({}, _.pick(sg, ['name', 'description', 'id', 'teams']), {indexedTeams: indexedTeams, isMatched: !!indexedTeams[model.id]});
          });

          ctrl.matchedFolders = _.filter(ctrl.folders, {isMatched: true});
        });
      }
    };
    folderRefresh();

    ctrl.editSiteOwnership = function(removeMode) {
      $uibModal.open({
        template: ownershipTemplate,
        size: 'lg',
        windowClass: '',
        resolve: {
          obj: function() {
            return ctrl.model;
          },
          matchedObjects: function() {
            return ctrl.matchedSites;
          }
        },
        controllerAs: 'ownerCtrl',
        controller: function($uibModalInstance) {
          const ownerCtrl = this;

          ownerCtrl.removeMode = removeMode;
          ownerCtrl.objects = removeMode ? angular.copy(ctrl.ownerSites) : angular.copy(ctrl.unownedSites);

          ownerCtrl.selectObjects = function() {
            ownerCtrl.matchedObjects = _.map(ownerCtrl.objects, 'id');
          };

          ownerCtrl.deselectObjects = function() {
            ownerCtrl.matchedObjects = [];
          };

          ownerCtrl.saveAndClose = function() {
            const objectsForUpdating = _.map(ownerCtrl.matchedObjects, function(objectId) {
              return {
                id: objectId,
                ownerTeam: removeMode ? null : _.pick(ctrl.model, ['id'])
              };
            });
            Site.bulkSave(objectsForUpdating).$promise.then(function() {
              ctrl.disabled = false;
              $uibModalInstance.close();
              adnModalLoading.removeModalLoading();
            }, function(errors) {
              ctrl.disabled = false;
              ownerCtrl.validationErrors = errors.data.errors;
              adnModalLoading.removeModalLoading();
            });
          };
        }
      })
        .closed.then(function() {
        ctrl.sites = null;
        siteRefresh();
        // delay just because of domain enforcer not getting there in time.
        $timeout(function() {
          ctrl.model.updateValidationWarnings();
        }, 500);
      });
    };

    ctrl.model.defaultTargeting = ctrl.model.defaultTargeting || {};
    ctrl.targetingTypes = _.map(ADN_TARGETING_TYPES, function(val) {
      return val;
    });

    ctrl.editTarget = function(targeting) {
      const typeTargeting = 'targetingResult';
      const apiParam = 'defaultTargeting';
      ctrl[typeTargeting] = {
        data: false
      };
      $uibModal.open({
        template: targetingTemplate,
        controller: 'TargetingCtrl',
        size: 'xl',
        resolve: {
          modelLineItem: function() {
            return angular.copy(ctrl.model.data || {});
          },
          pageType: function() {
            return "marketplaceProduct";
          },
          reachEnabled: function() {
          },
          mandatoryTargets: _.noop,
          availableTargets: function() {
            return null;
          },
          overridableTargeting: function() {
          },
          targetingMode: function() {
            return null;
          },
          targeting: function() {
            return targeting;
          },
          isLockedDown: function() {
            return ctrl.isLockedDown;
          }
        }
      })
        .result.then(function(wc) {
        _.assign(targeting, wc.wc);
        if (!ctrl.isNew) {
          ctrl.model.savePartial(apiParam).then(function(respLineItem) {
            ctrl[typeTargeting].data = true;
            _.set(ctrl.model, 'validationWarnings', respLineItem.validationWarnings);
          });
        }
      });
    };

    ctrl.editSiteMembership = function() {
      $uibModal.open({
        template: objTemplate,
        size: 'lg',
        windowClass: '',
        resolve: {
          theResource: function() {
            return Site;
          },
          translation: function() {
            return 'Site';
          },
          obj: function() {
            return ctrl.model;
          },
          objects: function() {
            return ctrl.sites;
          },
          matchedObjects: function() {
            return ctrl.matchedSites;
          }
        },
        controllerAs: 'objectCtrl',
        controller: 'ObjectMembershipCtrl'
      })
        .closed.then(function() {
        ctrl.sites = null;
        siteRefresh();
        // delay just because of domain enforcer not getting there in time.
        $timeout(function() {
          ctrl.model.updateValidationWarnings();
        }, 500);
      });
    };

    ctrl.editFolderMembership = function() {
      $uibModal.open({
        template: objTemplate,
        size: 'lg',
        windowClass: '',
        resolve: {
          theResource: function() {
            return Folder;
          },
          translation: function() {
            return 'Folder';
          },
          obj: function() {
            return ctrl.model;
          },
          objects: function() {
            return ctrl.folders;
          },
          matchedObjects: function() {
            return ctrl.matchedFolders;
          }
        },
        controllerAs: 'objectCtrl',
        controller: 'ObjectMembershipCtrl'
      })
        .closed.then(function() {
        ctrl.folders = null;
        folderRefresh();
        // delay just because of domain enforcer not getting there in time.
        $timeout(function() {
          ctrl.model.updateValidationWarnings();
        }, 500);
      });
    };

    ctrl.model.sites = _.map(ctrl.model.sites, 'id');
    ctrl.model.folders = _.map(ctrl.model.folders, 'id');

    getAvailableTeamTypes(ctrl, LocalNetworkProfile, LocalUserPermissions);

    ctrl.onBeforeSubmit = function() {
      let currentTeamGroupId = _.get(ctrl.model, ['teamGroup', 'id']);
      if (ctrl.canSeeTeamGroups && (currentTeamGroupId || originalTeamGroupId) && (currentTeamGroupId !== originalTeamGroupId)) {
        TeamGroup.updateTeamToTeamGroup(currentTeamGroupId || originalTeamGroupId, ctrl.model.id, !currentTeamGroupId);
      }
      preTeamSaveFix(ctrl);

      if (ctrl.isNew) {
        LocalNetworkInfo.obtain().increment('teamsCount');
      }
    };
  });

export function preTeamSaveFix(ctrl) {
  function sanitiseFee(param) {
    const feeClone = _.cloneDeep(ctrl.model[param]);
    ctrl.model[param] = null;
    if (feeClone.type === 'MONETARY') {
      const fixedFee = MoneyHelper.fixMoney(feeClone.monetary);
      if (MoneyHelper.isMoney(_.get(fixedFee, ['monetary']))) {
        ctrl.model[param] = {};
        ctrl.model[param].type = 'MONETARY';
        ctrl.model[param].monetary = fixedFee;
      }
    } else if (feeClone.type === 'PERCENTAGE') {
      if (_.isFinite(_.get(feeClone, ['percentage']))) {
        ctrl.model[param] = {};
        ctrl.model[param].type = 'PERCENTAGE';
        ctrl.model[param].percentage = feeClone.percentage;
      }
    } else if (_.isArray(feeClone.multiCurrencyMonetaryAmounts)) {
      const mFees = _.filter(_.map(feeClone.multiCurrencyMonetaryAmounts, function(m) {
        return MoneyHelper.fixMoney(m);
      }), function(fee) {
        return MoneyHelper.isMoney(fee);
      });
      if (mFees.length > 0) {
        ctrl.model[param] = {};
        ctrl.model[param].type = 'MULTI_CURRENCY_MONETARY';
        ctrl.model[param].multiCurrencyMonetaryAmounts = mFees;
      }
    }
  }

  if (!ctrl.model.type) {
    ctrl.model.type = 'STANDARD';
  }
  sanitiseFee('techFeeCpm');
  sanitiseFee('creativeTagTechFeeCpm');

  ctrl.model.supplementaryFees = _.filter(ctrl.model.supplementaryFees, function(fee) {
    if (!fee.name || !fee.type) {
      return false;
    }
    if (fee.type === 'PERCENTAGE' && _.isFinite(fee.percentage)) {
      delete fee.multiCurrencyMonetaryAmounts;
      return true;
    }
    const fixedFee = MoneyHelper.fixMoney(fee.monetary);
    if (fee.type === 'MONETARY' && MoneyHelper.isMoney(fixedFee)) {
      return true;
    }
    if (fee.type === 'MULTI_CURRENCY_MONETARY' && _.get(fee, ['multiCurrencyMonetaryAmounts'], []).length > 0) {
      delete fee.percentage;
      fee.multiCurrencyMonetaryAmounts = _.filter(_.map(fee.multiCurrencyMonetaryAmounts, function(m) {
        return MoneyHelper.fixMoney(m);
      }), function(f) {
        return MoneyHelper.isMoney(f);
      });
      return fee.multiCurrencyMonetaryAmounts.length > 0;
    }
  });
  ctrl.model.layoutFees = _.filter(ctrl.model.uiLayoutFees, function(layoutFee) {
    return _.get(layoutFee, ['layout', 'id']) && _.filter(_.map(_.get(layoutFee, ['fee', 'multiCurrencyMonetaryAmounts']), function(m) {
      return MoneyHelper.fixMoney(m);
    }), function(f) {
      return MoneyHelper.isMoney(f);
    }).length > 0;
  });
  ctrl.model.layoutFees = _.map(ctrl.model.layoutFees, function(layoutFee) {
    const adjFee = _.cloneDeep(layoutFee);
    adjFee.fee.multiCurrencyMonetaryAmounts = _.filter(_.map(adjFee.fee.multiCurrencyMonetaryAmounts, function(m) {
      return MoneyHelper.fixMoney(m);
    }), function(f) {
      return MoneyHelper.isMoney(f);
    });
    return adjFee;
  });

  const techFees = _.filter(ctrl.model.uiTargetingTypeTechFees, function(ttFee) {
    const fixedFee = _.get(ttFee.fee, ['monetary']);
    if (ttFee.fee.type === 'MONETARY' && MoneyHelper.isMoney(fixedFee)) {
      return ttFee.apiType;
    }
    if (ttFee.fee.type === 'PERCENTAGE' && ttFee.fee.percentage > 0) {
      return ttFee.apiType;
    }
    if (ttFee.fee.type === 'MULTI_CURRENCY_MONETARY' && _.get(ttFee, ['fee', 'multiCurrencyMonetaryAmounts'], []).length > 0) {
      ttFee.fee.multiCurrencyMonetaryAmounts = _.filter(_.map(ttFee.fee.multiCurrencyMonetaryAmounts, function(m) {
        return MoneyHelper.fixMoney(m);
      }), function(f) {
        return MoneyHelper.isMoney(f);
      });
      return ttFee.fee.multiCurrencyMonetaryAmounts.length > 0;
    }
    return false;
  });
  ctrl.model.targetingTypeTechFees = {};
  _.forEach(techFees, function(ttFee) {
    ctrl.model.targetingTypeTechFees[ttFee.apiType] = {
      type: ttFee.fee.type
    };
    if (ttFee.fee.type === 'MULTI_CURRENCY_MONETARY') {
      ctrl.model.targetingTypeTechFees[ttFee.apiType].multiCurrencyMonetaryAmounts = ttFee.fee.multiCurrencyMonetaryAmounts;
    } else {
      ctrl.model.targetingTypeTechFees[ttFee.apiType].percentage = ttFee.fee.percentage;
    }
  });
}

export function getAvailableTeamTypes(ctrl, LocalNetworkProfile, LocalUserPermissions) {
  // better than duplicating the networks logic but needs cleaning up
  ctrl.isSuiPlatform = LocalNetworkProfile.isSuiPlatform();
  ctrl.isAdvertisingPlatform = LocalNetworkProfile.isAdvertisingPlatform();
  ctrl.isMarketplacePlatform = LocalNetworkProfile.isMarketplacePlatform();
  ctrl.isAxAdvertiserAdmin = LocalUserPermissions.hasNetworkPermission('AX_ADVERTISER_ADMIN');

  if (ctrl.isAxAdvertiserAdmin) {
    ctrl.teamTypes = ['AX_ADVERTISER'];
  } else if (ctrl.isSuiPlatform) {
    ctrl.teamTypes = ['STANDARD', 'SUI', 'AX_ADVERTISER', 'AX_PUBLISHER'];
  } else if (ctrl.isAdvertisingPlatform) {
    ctrl.teamTypes = ['STANDARD'];
    if (ctrl.isMarketplacePlatform) {
      ctrl.teamTypes.push('AX_ADVERTISER');
      ctrl.teamTypes.push('AX_PUBLISHER');
      ctrl.teamTypes.push('CREATIVE_AGENCY');
    }
  }
}

export default MODULE_NAME;