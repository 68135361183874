/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import template from './semantic-targeting.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import searchPagination from '../../common/directives/pagination/search-pagination-component';
import {ADN_TARGETING_TYPES, CURRENCY_OR_COUNTRY_TO_LANG} from "../targeting-constants";
import moment from "moment/moment";

export const semanticMatchLevels = [
  {num: 0.3, key: '1 - Slightly related'},
  {num: 0.4, key: '2 - Somewhat related'},
  {num: 0.5, key: '3 - Moderately related'},
  {num: 0.6, key: '4 - Closely related'},
  {num: 0.7, key: '5 - Very closely related'}
];
const matchLevelsReversed = _.reverse(semanticMatchLevels);

export function doLevelMatch(score) {
  return _.find(matchLevelsReversed, function(s) {
    return score >= s.num;
  });
}

const MODULE_NAME = 'semantic-targeting-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, resources, promiseSpinner, searchPagination])

  .directive('adnSemanticTargeting', function(targetingEmbeddingSearchResource, articleSearchResource, $q) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnSemanticTargeting',
        underModel: '=',
        isLockedDown: '<',
        modelLineItem: '<',
        runningModel: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: {
        pre: function(scope) {
          scope.model = scope.model || [];
          scope.targetType = ADN_TARGETING_TYPES.semantic;
        },
        post: function(scope, element, attrs, tabset) {
          scope.loaded = true;

          scope.matchLevels = semanticMatchLevels;

          function resetSearch() {
            scope.searchMeta = {
              pageSize: 50,
              currentPage: 1,
              thresholdScore: 0.5,
              resolvedError: '',
              resolved: true
            };
          }

          function resetSearchAndInputFields() {
            resetSearch();
            scope.list = {};
          }

          resetSearchAndInputFields();

          scope.addNew = function() {
            scope.model.push({});
          };

          scope.editTarget = function(semTar) {
            resetSearchAndInputFields();
            _.forEach(scope.model, function(sem) {
              if (_.isEqual(semTar, sem)) {
                semTar.editable = !semTar.editable;
              } else {
                sem.editable = false;
              }
            });
            if (semTar.editable) {
              const num = _.findIndex(scope.model, function(sem) {
                return !_.get(sem, ['sentence', 'sentence']);
              });
              if (num > -1) {
                _.pullAt(scope.model, num);
              }
              scope.searchMeta.sentence = semTar.sentence.sentence;
              scope.searchMeta.thresholdScore = semTar.score;
            }
          };

          scope.removeTarget = function(index) {
            _.pullAt(scope.model, index);

            if (scope.model.length === 0) {
              scope.model.push({});
            }
            resetSearchAndInputFields();
          };

          scope.cancelResults = function() {
            scope.list = {};
          };

          scope.addToTargeting = function(negated, index) {
            if (!_.isString(scope.searchMeta.sentence) || _.trim(scope.searchMeta.sentence).length < 2 || !scope.searchMeta.thresholdScore) {
              return $q.resolve();
            }
            scope.embedding = true;
            return targetingEmbeddingSearchResource.query({sentence: scope.searchMeta.sentence}).then(function(page) {
              scope.model = scope.model || [];
              const modelEntry = {
                score: scope.searchMeta.thresholdScore,
                negated: !!negated,
                sentence: page,
                managedScore: doLevelMatch(scope.searchMeta.thresholdScore)
              };
              if (scope.model.length === 0) {
                scope.model.push(modelEntry);
              } else {
                scope.model[index] = modelEntry;
              }
              resetSearch();
              scope.embedding = false;
            });
          };

          scope.search = function(sem, index) {
            scope.resultsIndex = _.isFinite(sem) ? sem : index;
            scope.searchMeta.currentPage = 1;
            const searchParams = {
              sentence: _.get(sem, ['sentence', 'sentence'], scope.searchMeta.sentence || ''),
              thresholdScore: _.get(sem, ['score'], scope.searchMeta.thresholdScore),
              pageSize: scope.searchMeta.pageSize,
              page: scope.searchMeta.currentPage,
              concise: true
            };

            scope.searchMeta.resolved = false;
            searchParams.page = scope.searchMeta.page;

            searchParams.languages = "";
            const countriesSelected = scope.runningModel[ADN_TARGETING_TYPES.siteCountry.targets][ADN_TARGETING_TYPES.siteCountry.apiProp];
            if (_.isArray(countriesSelected)) {
              let languages = [];
              _.forEach(countriesSelected, function(code) {
                let langs = CURRENCY_OR_COUNTRY_TO_LANG[code];
                if (langs) {
                  languages = languages.concat(langs);
                }
              });
              searchParams.languages = languages.join(";");
            }
            if (!searchParams.languages) {
              const lineItemId = _.get(scope.modelLineItem, ['id'], undefined);
              if (lineItemId) {
                const productName = _.get(scope.modelLineItem, ['axProduct', 'name']) || "";
                const productCurrency = productName.indexOf("Norway") > -1 ? "NOK" : productName.indexOf("Sweden") > -1 ? "SEK" : productName.indexOf("Denmark") > -1 ? "DKK" : productName.indexOf("Danmark") > -1 ? "DKK" : undefined;
                const languages = CURRENCY_OR_COUNTRY_TO_LANG[productCurrency];
                if (_.isArray(languages)) {
                  searchParams.languages = languages.join(";");
                } else {
                  searchParams.lineItemId = lineItemId;
                }
              }
            }
            const runningModel = _.cloneDeep(scope.runningModel);
            delete runningModel[ADN_TARGETING_TYPES.semantic.targets];
            articleSearchResource.post(runningModel, searchParams).then(function(page) {
              scope.list = page;
              _.forEach(scope.list.results, function(p) {
                const profile = p;
                profile.count = 0;
                profile.managedScore = doLevelMatch(p.score);
                const firstSlash = profile.url.indexOf("/");
                profile.urlSplit = firstSlash > 0 ? [profile.url.substr(0, firstSlash), profile.url.substring(firstSlash)] : [];
                profile.title = profile.title || "No title found";
                profile.description = profile.description || "No description found";
                if (profile.publishedTime) {
                  profile.publishedTime = moment(profile.publishedTime).toDate().toDateString();
                }
                const imgUrl = profile.image;
                profile.image = (imgUrl && _.startsWith(imgUrl, "http")) ? imgUrl : '';
              });
              scope.searchMeta.resolved = true;
            });
          };

          let isPreparingForSave;
          let prepareForSave = function() {
            const num = _.findIndex(scope.model, function(sem) {
              return sem.editable;
            });
            isPreparingForSave = $q.when(scope.addToTargeting(false, num >= 0 ? num : (scope.model.length - 1))).then(function() {
              const indicesForRemoval = _.filter(_.map(scope.model, function(sem, index) {
                return _.get(sem, ['sentence', 'sentence']) ? -1 : index;
              }), function(num) {
                return num > -1;
              });
              _.pullAt(scope.model, indicesForRemoval);
              _.forEach(scope.model, function(s) {
                delete s.managedScore;
              });
            });
            return isPreparingForSave;
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            $q.when(prepareForSave()).then(function() {
              var summary = (scope.model.negated === true ? "NOT: " : "") + _.map(scope.model.semanticTargets, function(item) {
                return item.name;
              }).join(", ");

              return {
                vm: angular.copy(scope.model),
                model: angular.copy(scope.model),
                constant: ADN_TARGETING_TYPES.semantic,
                summary: summary
              };
            });
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register(ADN_TARGETING_TYPES.semantic.widget, () => {
            $q.when(isPreparingForSave).then(function() {
              _.forEach(scope.model, function(s) {
                if (s.score) {
                  s.managedScore = doLevelMatch(s.score);
                }
              });
              if (scope.model.length === 0) {
                scope.model.push({});
              }
            });
          }, copyTemplateFunc);
          tabset.callBeforeSubmit(prepareForSave);
          tabset.registerAllTabs(prepareForSave);
        }
      }
    };
  });

export default MODULE_NAME;

/*
const data = {
  "results": [
    {
      "contentProfileRecord": {
        "@c": ".ContentProfileRecord",
        "key": "www.svd.se/a/7f845227-7fcc-3c8c-810f-3829ae164fe7/bila-pa-greklands-glomda-fastland",
        "updateTime": "2023-08-04T10:04:52.738Z",
        "expiryTime": "2023-08-11T10:04:52.738Z",
        "dataSource": "ADNUNTIUS",
        "network": {
          "id": "try_opt",
          "tagId": "b7462",
          "name": "C ALT",
          "url": "/api/v1/networks/try_opt"
        },
        "language": "sv",
        "keyValues": [
          {
            "key": "keywords",
            "values": [
              {
                "value": "a",
                "score": 100
              },
              {
                "value": "fastland",
                "score": 100
              },
              {
                "value": "3c8c",
                "score": 100
              },
              {
                "value": "bad",
                "score": 100
              },
              {
                "value": "glomda",
                "score": 100
              },
              {
                "value": "7f845227",
                "score": 100
              },
              {
                "value": "greklands",
                "score": 100
              },
              {
                "value": "är",
                "score": 100
              },
              {
                "value": "3829ae164fe7",
                "score": 100
              },
              {
                "value": "pa",
                "score": 100
              },
              {
                "value": "bila",
                "score": 100
              },
              {
                "value": "grekland",
                "score": 100
              },
              {
                "value": "7fcc",
                "score": 100
              },
              {
                "value": "svenska",
                "score": 100
              },
              {
                "value": "ioannina",
                "score": 100
              },
              {
                "value": "grekiska",
                "score": 100
              }
            ]
          },
          {
            "key": "categories",
            "values": []
          },
          {
            "key": "words",
            "values": [
              {
                "value": "vackra",
                "score": 15
              },
              {
                "value": "helena",
                "score": 15
              },
              {
                "value": "bank",
                "score": 15
              },
              {
                "value": "svårt",
                "score": 15
              },
              {
                "value": "sköna",
                "score": 15
              },
              {
                "value": "kombinera",
                "score": 15
              },
              {
                "value": "slutet",
                "score": 15
              },
              {
                "value": "tid",
                "score": 15
              },
              {
                "value": "städer",
                "score": 15
              },
              {
                "value": "hälsningar",
                "score": 15
              },
              {
                "value": "förväg",
                "score": 15
              },
              {
                "value": "östkusten",
                "score": 15
              },
              {
                "value": "kreditkort",
                "score": 15
              },
              {
                "value": "beror",
                "score": 15
              },
              {
                "value": "spendera",
                "score": 15
              },
              {
                "value": "västkusten",
                "score": 15
              },
              {
                "value": "öst",
                "score": 15
              },
              {
                "value": "ställen",
                "score": 15
              },
              {
                "value": "hört",
                "score": 15
              },
              {
                "value": "västkustalternativet",
                "score": 15
              },
              {
                "value": "mil",
                "score": 15
              },
              {
                "value": "boende",
                "score": 15
              },
              {
                "value": "vägen",
                "score": 15
              },
              {
                "value": "svd",
                "score": 15
              },
              {
                "value": "bergström",
                "score": 15
              },
              {
                "value": "förvånansvärt",
                "score": 15
              },
              {
                "value": "glömda",
                "score": 15
              },
              {
                "value": "vuxna",
                "score": 15
              },
              {
                "value": "bör",
                "score": 15
              },
              {
                "value": "juli",
                "score": 15
              },
              {
                "value": "inlandet",
                "score": 15
              },
              {
                "value": "fastlandet",
                "score": 15
              },
              {
                "value": "väst",
                "score": 15
              },
              {
                "value": "landsvägarna",
                "score": 15
              },
              {
                "value": "bortglömt",
                "score": 15
              },
              {
                "value": "funkar",
                "score": 15
              },
              {
                "value": "hej",
                "score": 15
              },
              {
                "value": "boka",
                "score": 15
              },
              {
                "value": "trevliga",
                "score": 15
              },
              {
                "value": "pamvotissjöns",
                "score": 15
              },
              {
                "value": "tilltalande",
                "score": 15
              },
              {
                "value": "strand",
                "score": 15
              },
              {
                "value": "undrar",
                "score": 15
              },
              {
                "value": "åka",
                "score": 15
              },
              {
                "value": "parga",
                "score": 15
              },
              {
                "value": "stämmer",
                "score": 15
              },
              {
                "value": "lugnt",
                "score": 15
              },
              {
                "value": "läge",
                "score": 15
              },
              {
                "value": "tonåringar",
                "score": 15
              },
              {
                "value": "resenärer",
                "score": 15
              },
              {
                "value": "exempel",
                "score": 15
              },
              {
                "value": "hitta",
                "score": 15
              },
              {
                "value": "staden",
                "score": 15
              },
              {
                "value": "familj",
                "score": 15
              },
              {
                "value": "avverkar",
                "score": 15
              },
              {
                "value": "makedonien",
                "score": 15
              },
              {
                "value": "vikosravinen",
                "score": 15
              },
              {
                "value": "missa",
                "score": 15
              },
              {
                "value": "svaret",
                "score": 15
              },
              {
                "value": "bilkartor",
                "score": 15
              },
              {
                "value": "tar",
                "score": 15
              }
            ]
          }
        ],
        "properties": {
          "image": "https://svd.vgc.no/v2/images/34fd2c0c-57fb-46a4-9e5a-591e37aa6b16?h=630&q=80&upscale=true&w=1200&s=447e1e51975ca6b74f3afd5c0fae1e172452a9a9",
          "publishedTime": "2011-03-18T08:39:37Z",
          "description": "Hej, vi en familj med två vuxna och två tonåringar ska bila i Makedonien och Grekland i slutet av juli. Vi har aldrig varit i Grekland och undrar om vi ska åka mot öst- eller västkusten och vilka ställen på vägen vi inte bör missa. Vi tar det hellre lugnt än avverkar många mil. Stämmer det som jag hört att det är svårt att hitta bra bilkartor öv...",
          "type": "article",
          "title": "Bila på Greklands glömda fastland | SvD"
        },
        "publishedTime": "2011-03-18T08:39:37Z",
        "hash": 5343136
      },
      "score": 0.7372587482468019
    },
    {
      "contentProfileRecord": {
        "@c": ".ContentProfileRecord",
        "key": "www.startsiden.no/guider/oyhopping-i-hellas-prov-denne-reiseruten",
        "updateTime": "2023-08-08T12:04:57.790Z",
        "expiryTime": "2023-08-15T12:04:57.790Z",
        "dataSource": "ADNUNTIUS",
        "network": {
          "id": "try_opt",
          "tagId": "b7462",
          "name": "C ALT",
          "url": "/api/v1/networks/try_opt"
        },
        "language": "no",
        "keyValues": [
          {
            "key": "keywords",
            "values": [
              {
                "value": "reiseruten",
                "score": 100
              },
              {
                "value": "abc",
                "score": 100
              },
              {
                "value": "saken",
                "score": 100
              },
              {
                "value": "alltid",
                "score": 100
              },
              {
                "value": "bestemmer",
                "score": 100
              },
              {
                "value": "startsiden",
                "score": 100
              },
              {
                "value": "produkter",
                "score": 100
              },
              {
                "value": "annonselenkene",
                "score": 100
              },
              {
                "value": "saker",
                "score": 100
              },
              {
                "value": "butikk",
                "score": 100
              },
              {
                "value": "butikker",
                "score": 100
              },
              {
                "value": "priser",
                "score": 100
              },
              {
                "value": "innholdet",
                "score": 100
              },
              {
                "value": "guider",
                "score": 100
              },
              {
                "value": "hellas",
                "score": 100
              },
              {
                "value": "produktene",
                "score": 100
              },
              {
                "value": "pris",
                "score": 100
              },
              {
                "value": "sakene",
                "score": 100
              },
              {
                "value": "oyhopping",
                "score": 100
              },
              {
                "value": "annonselenker",
                "score": 100
              },
              {
                "value": "lenkene",
                "score": 100
              },
              {
                "value": "prov",
                "score": 100
              },
              {
                "value": "guide",
                "score": 100
              },
              {
                "value": "relevante",
                "score": 100
              }
            ]
          },
          {
            "key": "categories",
            "values": [
              {
                "value": "IAB_23",
                "score": 100
              },
              {
                "value": "IAB_25",
                "score": 100
              }
            ]
          },
          {
            "key": "words",
            "values": [
              {
                "value": "copyright",
                "score": 15
              },
              {
                "value": "journalistene",
                "score": 15
              },
              {
                "value": "slitesterke",
                "score": 15
              },
              {
                "value": "annonsørene",
                "score": 15
              },
              {
                "value": "redaksjonen",
                "score": 15
              },
              {
                "value": "fjellstøvel",
                "score": 15
              },
              {
                "value": "nyheter",
                "score": 15
              },
              {
                "value": "robuste",
                "score": 15
              },
              {
                "value": "publiseringen",
                "score": 15
              },
              {
                "value": "del",
                "score": 15
              },
              {
                "value": "produksjonen",
                "score": 15
              },
              {
                "value": "fjær",
                "score": 15
              },
              {
                "value": "innholdsprodusenter",
                "score": 15
              },
              {
                "value": "lett",
                "score": 15
              },
              {
                "value": "tjenester",
                "score": 15
              },
              {
                "value": "utsolgt",
                "score": 15
              },
              {
                "value": "superdeal",
                "score": 15
              },
              {
                "value": "rolle",
                "score": 15
              },
              {
                "value": "inneholder",
                "score": 15
              },
              {
                "value": "valgt",
                "score": 15
              },
              {
                "value": "får",
                "score": 15
              },
              {
                "value": "nyttige",
                "score": 15
              },
              {
                "value": "blanding",
                "score": 15
              },
              {
                "value": "øyhopping",
                "score": 15
              },
              {
                "value": "gir",
                "score": 15
              },
              {
                "value": "prøv",
                "score": 15
              },
              {
                "value": "klikker",
                "score": 15
              },
              {
                "value": "kjøpe",
                "score": 15
              },
              {
                "value": "tex",
                "score": 15
              },
              {
                "value": "sikre",
                "score": 15
              },
              {
                "value": "fineste",
                "score": 15
              },
              {
                "value": "©",
                "score": 15
              },
              {
                "value": "mål",
                "score": 15
              },
              {
                "value": "merket",
                "score": 15
              },
              {
                "value": "gore",
                "score": 15
              },
              {
                "value": "spar",
                "score": 15
              },
              {
                "value": "annonsørinnholdet",
                "score": 15
              },
              {
                "value": "vårt",
                "score": 15
              },
              {
                "value": "mest",
                "score": 15
              },
              {
                "value": "endelig",
                "score": 15
              },
              {
                "value": "dunjakken",
                "score": 15
              },
              {
                "value": "dun",
                "score": 15
              },
              {
                "value": "\uD83E\uDEB6",
                "score": 15
              },
              {
                "value": "as",
                "score": 15
              },
              {
                "value": "jakken",
                "score": 15
              },
              {
                "value": "fantastisk",
                "score": 15
              },
              {
                "value": "lar",
                "score": 15
              },
              {
                "value": "årets",
                "score": 15
              },
              {
                "value": "sammenligne",
                "score": 15
              },
              {
                "value": "startsiden.no",
                "score": 15
              },
              {
                "value": "omtales",
                "score": 15
              },
              {
                "value": "vanntett",
                "score": 15
              },
              {
                "value": "komfortabel",
                "score": 15
              },
              {
                "value": "skrive",
                "score": 15
              },
              {
                "value": "arc’teryx",
                "score": 15
              },
              {
                "value": "annonseinntekter",
                "score": 15
              },
              {
                "value": "inntekter",
                "score": 15
              },
              {
                "value": "beste",
                "score": 15
              },
              {
                "value": "klassisk",
                "score": 15
              },
              {
                "value": "omtalt",
                "score": 15
              },
              {
                "value": "fleecejakke",
                "score": 15
              }
            ]
          }
        ],
        "properties": {
          "image": "https://guides-wp.startsiden.no/wp-content/uploads/2022/04/santorini-scaled.jpg",
          "publishedTime": "2022-04-29T08:55:52Z",
          "description": "Øyhopping i Hellas er manges store feriedrøm. Her er vårt forslag til reiserute fra Santorini eller Athen.",
          "type": "article",
          "title": "Øyhopping i Hellas: Prøv denne reiseruten"
        },
        "publishedTime": "2022-04-29T08:55:52Z",
        "hash": 6988392
      },
      "score": 0.728438270455038
    },
    {
      "contentProfileRecord": {
        "@c": ".ContentProfileRecord",
        "key": "www.vg.no/forbruker/reise/i/BR2jpE/thirassia-utenfor-santorini-en-tidsreise-til-hellas-foer-turistene",
        "updateTime": "2023-08-13T17:33:41.980Z",
        "expiryTime": "2023-08-20T17:33:41.980Z",
        "dataSource": "ADNUNTIUS",
        "network": {
          "id": "try_opt",
          "tagId": "b7462",
          "name": "C ALT",
          "url": "/api/v1/networks/try_opt"
        },
        "language": "no",
        "keyValues": [
          {
            "key": "keywords",
            "values": [
              {
                "value": "havn",
                "score": 100
              },
              {
                "value": "øyas",
                "score": 100
              },
              {
                "value": "øya",
                "score": 100
              },
              {
                "value": "bor",
                "score": 100
              },
              {
                "value": "tidene",
                "score": 100
              },
              {
                "value": "foer",
                "score": 100
              },
              {
                "value": "årets",
                "score": 100
              },
              {
                "value": "ligger",
                "score": 100
              },
              {
                "value": "turistene",
                "score": 100
              },
              {
                "value": "santorini",
                "score": 100
              },
              {
                "value": "ganger",
                "score": 100
              },
              {
                "value": "hos",
                "score": 100
              },
              {
                "value": "tid",
                "score": 100
              },
              {
                "value": "hit",
                "score": 100
              },
              {
                "value": "kommer",
                "score": 100
              },
              {
                "value": "går",
                "score": 100
              },
              {
                "value": "beste",
                "score": 100
              },
              {
                "value": "hellas",
                "score": 100
              },
              {
                "value": "thirassia",
                "score": 100
              },
              {
                "value": "havnen",
                "score": 100
              },
              {
                "value": "reise",
                "score": 100
              },
              {
                "value": "gang",
                "score": 100
              },
              {
                "value": "rabatt",
                "score": 100
              },
              {
                "value": "br2jpe",
                "score": 100
              },
              {
                "value": "tidsreise",
                "score": 100
              },
              {
                "value": "jimmys",
                "score": 100
              },
              {
                "value": "manolas",
                "score": 100
              },
              {
                "value": "tiden",
                "score": 100
              },
              {
                "value": "år",
                "score": 100
              },
              {
                "value": "utenfor",
                "score": 100
              },
              {
                "value": "gangen",
                "score": 100
              },
              {
                "value": "tidlig",
                "score": 100
              },
              {
                "value": "helt",
                "score": 100
              },
              {
                "value": "året",
                "score": 100
              },
              {
                "value": "komme",
                "score": 100
              },
              {
                "value": "forbruker",
                "score": 100
              },
              {
                "value": "jimmy",
                "score": 100
              }
            ]
          },
          {
            "key": "categories",
            "values": []
          },
          {
            "key": "words",
            "values": [
              {
                "value": "spektakulær",
                "score": 15
              },
              {
                "value": "flyttet",
                "score": 15
              },
              {
                "value": "folksomt",
                "score": 15
              },
              {
                "value": "new",
                "score": 15
              },
              {
                "value": "pensjonisten",
                "score": 15
              },
              {
                "value": "trenger",
                "score": 15
              },
              {
                "value": "minste",
                "score": 15
              },
              {
                "value": "sesong",
                "score": 15
              },
              {
                "value": "billetten",
                "score": 15
              },
              {
                "value": "nytelse",
                "score": 15
              },
              {
                "value": "videregående",
                "score": 15
              },
              {
                "value": "stillheten",
                "score": 15
              },
              {
                "value": "fergebilletter",
                "score": 15
              },
              {
                "value": "skuldrene",
                "score": 15
              },
              {
                "value": "overnattingstilbud",
                "score": 15
              },
              {
                "value": "ellers",
                "score": 15
              },
              {
                "value": "hjem",
                "score": 15
              },
              {
                "value": "frem",
                "score": 15
              },
              {
                "value": "auberginerettene",
                "score": 15
              },
              {
                "value": "rabattkode",
                "score": 15
              },
              {
                "value": "minutters",
                "score": 15
              },
              {
                "value": "klart",
                "score": 15
              },
              {
                "value": "sørspissen",
                "score": 15
              },
              {
                "value": "under",
                "score": 15
              },
              {
                "value": "dukket",
                "score": 15
              },
              {
                "value": "verdens",
                "score": 15
              },
              {
                "value": "bunnen",
                "score": 15
              },
              {
                "value": "basseng",
                "score": 15
              },
              {
                "value": "åtte",
                "score": 15
              },
              {
                "value": "klarer",
                "score": 15
              },
              {
                "value": "fraflyttede",
                "score": 15
              },
              {
                "value": "sier",
                "score": 15
              },
              {
                "value": "bukten",
                "score": 15
              },
              {
                "value": "aller",
                "score": 15
              },
              {
                "value": "mente",
                "score": 15
              },
              {
                "value": "utsikten",
                "score": 15
              },
              {
                "value": "flotte",
                "score": 15
              },
              {
                "value": "fem",
                "score": 15
              },
              {
                "value": "panoramautsikt",
                "score": 15
              },
              {
                "value": "rom",
                "score": 15
              },
              {
                "value": "leie",
                "score": 15
              },
              {
                "value": "betongkaien",
                "score": 15
              },
              {
                "value": "sammen",
                "score": 15
              },
              {
                "value": "ærender",
                "score": 15
              },
              {
                "value": "skoler",
                "score": 15
              },
              {
                "value": "restaurert",
                "score": 15
              },
              {
                "value": "konkurrenten",
                "score": 15
              },
              {
                "value": "mat",
                "score": 15
              },
              {
                "value": "fjelltoppen",
                "score": 15
              },
              {
                "value": "sardiner",
                "score": 15
              },
              {
                "value": "korrekt",
                "score": 15
              },
              {
                "value": "rett",
                "score": 15
              },
              {
                "value": "hatt",
                "score": 15
              },
              {
                "value": "grunner",
                "score": 15
              },
              {
                "value": "bygget",
                "score": 15
              },
              {
                "value": "endte",
                "score": 15
              },
              {
                "value": "thirassa",
                "score": 15
              },
              {
                "value": "dagen",
                "score": 15
              },
              {
                "value": "sjelen",
                "score": 15
              },
              {
                "value": "katamaran",
                "score": 15
              },
              {
                "value": "katamaranene",
                "score": 15
              },
              {
                "value": "dagscruise",
                "score": 15
              },
              {
                "value": "vinbonden",
                "score": 15
              },
              {
                "value": "dro",
                "score": 15
              },
              {
                "value": "steinovnen",
                "score": 15
              },
              {
                "value": "grillede",
                "score": 15
              },
              {
                "value": "turister",
                "score": 15
              },
              {
                "value": "mennene",
                "score": 15
              },
              {
                "value": "kloster",
                "score": 15
              },
              {
                "value": "måneder",
                "score": 15
              },
              {
                "value": "informasjon",
                "score": 15
              },
              {
                "value": "enda",
                "score": 15
              },
              {
                "value": "skjønte",
                "score": 15
              },
              {
                "value": "merkede",
                "score": 15
              },
              {
                "value": "bygde",
                "score": 15
              },
              {
                "value": "klare",
                "score": 15
              },
              {
                "value": "ruinlandsbyen",
                "score": 15
              },
              {
                "value": "november",
                "score": 15
              },
              {
                "value": "kai",
                "score": 15
              },
              {
                "value": "få",
                "score": 15
              },
              {
                "value": "sjøs",
                "score": 15
              },
              {
                "value": "hang",
                "score": 15
              },
              {
                "value": "jotex",
                "score": 15
              },
              {
                "value": "pakke",
                "score": 15
              },
              {
                "value": "røyken",
                "score": 15
              },
              {
                "value": "gratis",
                "score": 15
              },
              {
                "value": "øyene",
                "score": 15
              },
              {
                "value": "pensjonister",
                "score": 15
              },
              {
                "value": "elsker",
                "score": 15
              },
              {
                "value": "mye",
                "score": 15
              },
              {
                "value": "drømme",
                "score": 15
              },
              {
                "value": "lytt",
                "score": 15
              },
              {
                "value": "kjøp",
                "score": 15
              },
              {
                "value": "kona",
                "score": 15
              },
              {
                "value": "forteller",
                "score": 15
              },
              {
                "value": "litt",
                "score": 15
              },
              {
                "value": "sirgos",
                "score": 15
              },
              {
                "value": "vulkanutbruddet",
                "score": 15
              },
              {
                "value": "akkurat",
                "score": 15
              },
              {
                "value": "rykte",
                "score": 15
              },
              {
                "value": "ankre",
                "score": 15
              },
              {
                "value": "riva",
                "score": 15
              },
              {
                "value": "sykehus",
                "score": 15
              },
              {
                "value": "båten",
                "score": 15
              },
              {
                "value": "serverer",
                "score": 15
              },
              {
                "value": "meter",
                "score": 15
              },
              {
                "value": "menyen",
                "score": 15
              },
              {
                "value": "vakkert",
                "score": 15
              },
              {
                "value": "betyr",
                "score": 15
              },
              {
                "value": "dragning",
                "score": 15
              },
              {
                "value": "nabo",
                "score": 15
              },
              {
                "value": "kirker",
                "score": 15
              },
              {
                "value": "turstier",
                "score": 15
              },
              {
                "value": "utrolig",
                "score": 15
              },
              {
                "value": "frossenmat",
                "score": 15
              },
              {
                "value": "dra",
                "score": 15
              },
              {
                "value": "hele",
                "score": 15
              },
              {
                "value": "friterte",
                "score": 15
              },
              {
                "value": "to",
                "score": 15
              },
              {
                "value": "tilbake",
                "score": 15
              },
              {
                "value": "fortsatt",
                "score": 15
              },
              {
                "value": "øylivet",
                "score": 15
              },
              {
                "value": "tok",
                "score": 15
              },
              {
                "value": "santoriniexperts.com",
                "score": 15
              },
              {
                "value": "toppen",
                "score": 15
              },
              {
                "value": "leier",
                "score": 15
              },
              {
                "value": "ektemenn",
                "score": 15
              },
              {
                "value": "gammelt",
                "score": 15
              },
              {
                "value": "utslettet",
                "score": 15
              },
              {
                "value": "må",
                "score": 15
              },
              {
                "value": "anna",
                "score": 15
              },
              {
                "value": "løpet",
                "score": 15
              },
              {
                "value": "snartur",
                "score": 15
              },
              {
                "value": "veikantene",
                "score": 15
              },
              {
                "value": "deler",
                "score": 15
              },
              {
                "value": "avhengig",
                "score": 15
              },
              {
                "value": "profetis",
                "score": 15
              },
              {
                "value": "satt",
                "score": 15
              },
              {
                "value": "helikopter",
                "score": 15
              },
              {
                "value": "lite",
                "score": 15
              },
              {
                "value": "taverna",
                "score": 15
              },
              {
                "value": "konditorvarer",
                "score": 15
              },
              {
                "value": "perfekt",
                "score": 15
              },
              {
                "value": "stor",
                "score": 15
              },
              {
                "value": "fjor",
                "score": 15
              },
              {
                "value": "tallet",
                "score": 15
              },
              {
                "value": "begynner",
                "score": 15
              },
              {
                "value": "turistmylderet",
                "score": 15
              },
              {
                "value": "smalt",
                "score": 15
              },
              {
                "value": "jobbet",
                "score": 15
              },
              {
                "value": "lille",
                "score": 15
              },
              {
                "value": "sjømannssekken",
                "score": 15
              },
              {
                "value": "virker",
                "score": 15
              },
              {
                "value": "lydbøker",
                "score": 15
              },
              {
                "value": "ferskt",
                "score": 15
              },
              {
                "value": "gjennom",
                "score": 15
              },
              {
                "value": "drømmer",
                "score": 15
              },
              {
                "value": "bestilling",
                "score": 15
              },
              {
                "value": "retur",
                "score": 15
              },
              {
                "value": "ute",
                "score": 15
              },
              {
                "value": "hverken",
                "score": 15
              },
              {
                "value": "minoiske",
                "score": 15
              },
              {
                "value": "drevet",
                "score": 15
              },
              {
                "value": "oia",
                "score": 15
              },
              {
                "value": "vanskelig",
                "score": 15
              },
              {
                "value": "dukker",
                "score": 15
              },
              {
                "value": "poppet",
                "score": 15
              },
              {
                "value": "vokser",
                "score": 15
              },
              {
                "value": "tomatboller",
                "score": 15
              },
              {
                "value": "første",
                "score": 15
              },
              {
                "value": "lurer",
                "score": 15
              },
              {
                "value": "endret",
                "score": 15
              },
              {
                "value": "båttur",
                "score": 15
              },
              {
                "value": "nærheten",
                "score": 15
              },
              {
                "value": "nettet",
                "score": 15
              },
              {
                "value": "regnes",
                "score": 15
              },
              {
                "value": "hundre",
                "score": 15
              },
              {
                "value": "lå",
                "score": 15
              },
              {
                "value": "raskt",
                "score": 15
              },
              {
                "value": "utbygging",
                "score": 15
              },
              {
                "value": "formiddag",
                "score": 15
              },
              {
                "value": "nattklubber",
                "score": 15
              },
              {
                "value": "tar",
                "score": 15
              },
              {
                "value": "rabattkoden",
                "score": 15
              },
              {
                "value": "historien",
                "score": 15
              },
              {
                "value": "forbli",
                "score": 15
              },
              {
                "value": "lage",
                "score": 15
              },
              {
                "value": "\uD83C\uDFA7",
                "score": 15
              },
              {
                "value": "mer",
                "score": 15
              },
              {
                "value": "andre",
                "score": 15
              },
              {
                "value": "finne",
                "score": 15
              },
              {
                "value": "brød",
                "score": 15
              },
              {
                "value": "familiene",
                "score": 15
              },
              {
                "value": "byen",
                "score": 15
              },
              {
                "value": "flere",
                "score": 15
              },
              {
                "value": "turismen",
                "score": 15
              },
              {
                "value": "senket",
                "score": 15
              },
              {
                "value": "finnes",
                "score": 15
              },
              {
                "value": "eksempel",
                "score": 15
              },
              {
                "value": "folk",
                "score": 15
              },
              {
                "value": "mesteparten",
                "score": 15
              },
              {
                "value": "helst",
                "score": 15
              },
              {
                "value": "kalderaen",
                "score": 15
              },
              {
                "value": "variere",
                "score": 15
              },
              {
                "value": "kraftigste",
                "score": 15
              },
              {
                "value": "kastet",
                "score": 15
              },
              {
                "value": "marked",
                "score": 15
              },
              {
                "value": "krattet",
                "score": 15
              },
              {
                "value": "kollapset",
                "score": 15
              },
              {
                "value": "sommerferie",
                "score": 15
              },
              {
                "value": "grottehus",
                "score": 15
              },
              {
                "value": "nybakte",
                "score": 15
              },
              {
                "value": "lenger",
                "score": 15
              },
              {
                "value": "jo",
                "score": 15
              },
              {
                "value": "paradis",
                "score": 15
              },
              {
                "value": "får",
                "score": 15
              },
              {
                "value": "reisebyrå",
                "score": 15
              },
              {
                "value": "spisestedet",
                "score": 15
              },
              {
                "value": "barnehage",
                "score": 15
              },
              {
                "value": "overbevist",
                "score": 15
              },
              {
                "value": "vennene",
                "score": 15
              },
              {
                "value": "ro",
                "score": 15
              },
              {
                "value": "bassenget",
                "score": 15
              },
              {
                "value": "blikket",
                "score": 15
              },
              {
                "value": "innbyggerne",
                "score": 15
              },
              {
                "value": "ilias",
                "score": 15
              },
              {
                "value": "koker",
                "score": 15
              },
              {
                "value": "paradise",
                "score": 15
              },
              {
                "value": "sundet",
                "score": 15
              },
              {
                "value": "sett",
                "score": 15
              },
              {
                "value": "kaffe",
                "score": 15
              },
              {
                "value": "york",
                "score": 15
              },
              {
                "value": "nesten",
                "score": 15
              },
              {
                "value": "vg",
                "score": 15
              },
              {
                "value": "tre",
                "score": 15
              },
              {
                "value": "vulkanutbrudd",
                "score": 15
              },
              {
                "value": "bygd",
                "score": 15
              },
              {
                "value": "an",
                "score": 15
              },
              {
                "value": "utseende",
                "score": 15
              },
              {
                "value": "tavernaer",
                "score": 15
              },
              {
                "value": "sa",
                "score": 15
              },
              {
                "value": "rutebåtkaien",
                "score": 15
              },
              {
                "value": "stab",
                "score": 15
              },
              {
                "value": "ser",
                "score": 15
              },
              {
                "value": "euro",
                "score": 15
              },
              {
                "value": "vinstokkene",
                "score": 15
              },
              {
                "value": "åpningstidene",
                "score": 15
              },
              {
                "value": "eneste",
                "score": 15
              },
              {
                "value": "kr",
                "score": 15
              },
              {
                "value": "blivakker",
                "score": 15
              },
              {
                "value": "barna",
                "score": 15
              },
              {
                "value": "havbunnen",
                "score": 15
              },
              {
                "value": "dagligvarebutikk",
                "score": 15
              },
              {
                "value": "gigantiske",
                "score": 15
              },
              {
                "value": "venter",
                "score": 15
              },
              {
                "value": "kanskje",
                "score": 15
              },
              {
                "value": "kilometer",
                "score": 15
              },
              {
                "value": "adonis",
                "score": 15
              },
              {
                "value": "skole",
                "score": 15
              },
              {
                "value": "sjekke",
                "score": 15
              },
              {
                "value": "beskjærer",
                "score": 15
              },
              {
                "value": "bestemte",
                "score": 15
              },
              {
                "value": "overrasket",
                "score": 15
              },
              {
                "value": "utleiemarkedet",
                "score": 15
              },
              {
                "value": "legger",
                "score": 15
              },
              {
                "value": "stedet",
                "score": 15
              },
              {
                "value": "ordføreren",
                "score": 15
              },
              {
                "value": "åpnet",
                "score": 15
              },
              {
                "value": "boobeat",
                "score": 15
              },
              {
                "value": "agrilia",
                "score": 15
              },
              {
                "value": "trær",
                "score": 15
              },
              {
                "value": "gjøre",
                "score": 15
              },
              {
                "value": "åpner",
                "score": 15
              },
              {
                "value": "levebrød",
                "score": 15
              },
              {
                "value": "kaster",
                "score": 15
              },
              {
                "value": "gresk",
                "score": 15
              },
              {
                "value": "anno",
                "score": 15
              },
              {
                "value": "maten",
                "score": 15
              },
              {
                "value": "alt",
                "score": 15
              },
              {
                "value": "rutebåten",
                "score": 15
              },
              {
                "value": "midten",
                "score": 15
              },
              {
                "value": "humrer",
                "score": 15
              },
              {
                "value": "nettsidene",
                "score": 15
              },
              {
                "value": "par",
                "score": 15
              },
              {
                "value": "gliser",
                "score": 15
              },
              {
                "value": "anlegget",
                "score": 15
              },
              {
                "value": "båtene",
                "score": 15
              },
              {
                "value": "luksusvilla",
                "score": 15
              },
              {
                "value": "slutten",
                "score": 15
              },
              {
                "value": "utsikt",
                "score": 15
              },
              {
                "value": "avgangstidene",
                "score": 15
              },
              {
                "value": "ammoudi",
                "score": 15
              },
              {
                "value": "vulkanen",
                "score": 15
              },
              {
                "value": "sommerstid",
                "score": 15
              },
              {
                "value": "paier",
                "score": 15
              },
              {
                "value": "tilby",
                "score": 15
              },
              {
                "value": "stranden",
                "score": 15
              },
              {
                "value": "interiør",
                "score": 15
              },
              {
                "value": "dagscruiseturistene",
                "score": 15
              },
              {
                "value": "gikk",
                "score": 15
              },
              {
                "value": "personer",
                "score": 15
              },
              {
                "value": "fedre",
                "score": 15
              },
              {
                "value": "tror",
                "score": 15
              },
              {
                "value": "lever",
                "score": 15
              },
              {
                "value": "flytte",
                "score": 15
              },
              {
                "value": "fjæresteinene",
                "score": 15
              },
              {
                "value": "sjøfolk",
                "score": 15
              },
              {
                "value": "sted",
                "score": 15
              },
              {
                "value": "ordinære",
                "score": 15
              },
              {
                "value": "tikker",
                "score": 15
              },
              {
                "value": "flesteparten",
                "score": 15
              },
              {
                "value": "brygga",
                "score": 15
              },
              {
                "value": "zacharo",
                "score": 15
              },
              {
                "value": "valgte",
                "score": 15
              },
              {
                "value": "langt",
                "score": 15
              },
              {
                "value": "stjernes",
                "score": 15
              },
              {
                "value": "suites",
                "score": 15
              },
              {
                "value": "herfra",
                "score": 15
              },
              {
                "value": "grunnskole",
                "score": 15
              },
              {
                "value": "hollywood",
                "score": 15
              },
              {
                "value": "biter",
                "score": 15
              },
              {
                "value": "leilighetsbygg",
                "score": 15
              },
              {
                "value": "prøv",
                "score": 15
              },
              {
                "value": "storbyen",
                "score": 15
              },
              {
                "value": "sund",
                "score": 15
              },
              {
                "value": "siste",
                "score": 15
              },
              {
                "value": "utbruddet",
                "score": 15
              },
              {
                "value": "selger",
                "score": 15
              },
              {
                "value": "studios",
                "score": 15
              },
              {
                "value": "lages",
                "score": 15
              },
              {
                "value": "stjerner",
                "score": 15
              },
              {
                "value": "klokken",
                "score": 15
              },
              {
                "value": "tavernaen",
                "score": 15
              },
              {
                "value": "drar",
                "score": 15
              },
              {
                "value": "rykter",
                "score": 15
              },
              {
                "value": "hoteller",
                "score": 15
              },
              {
                "value": "fryktelig",
                "score": 15
              },
              {
                "value": "står",
                "score": 15
              },
              {
                "value": "vekk",
                "score": 15
              },
              {
                "value": "beskyttet",
                "score": 15
              },
              {
                "value": "stenger",
                "score": 15
              },
              {
                "value": "upåvirket",
                "score": 15
              },
              {
                "value": "nedenfor",
                "score": 15
              },
              {
                "value": "vakrere",
                "score": 15
              },
              {
                "value": "egen",
                "score": 15
              },
              {
                "value": "steinkast",
                "score": 15
              },
              {
                "value": "store",
                "score": 15
              },
              {
                "value": "unge",
                "score": 15
              },
              {
                "value": "grunt",
                "score": 15
              },
              {
                "value": "elleve",
                "score": 15
              },
              {
                "value": "fant",
                "score": 15
              },
              {
                "value": "aldri",
                "score": 15
              },
              {
                "value": "selge",
                "score": 15
              },
              {
                "value": "entreprenørfirma",
                "score": 15
              },
              {
                "value": "butikker",
                "score": 15
              },
              {
                "value": "nydelig",
                "score": 15
              },
              {
                "value": "sivilisasjonen",
                "score": 15
              },
              {
                "value": "koster",
                "score": 15
              },
              {
                "value": "gjester",
                "score": 15
              },
              {
                "value": "alltid",
                "score": 15
              },
              {
                "value": "delte",
                "score": 15
              },
              {
                "value": "baker",
                "score": 15
              },
              {
                "value": "nikos",
                "score": 15
              },
              {
                "value": "ankommer",
                "score": 15
              },
              {
                "value": "hører",
                "score": 15
              },
              {
                "value": "brenselet",
                "score": 15
              },
              {
                "value": "tilpasset",
                "score": 15
              },
              {
                "value": "scandic",
                "score": 15
              },
              {
                "value": "restauranter",
                "score": 15
              },
              {
                "value": "grenene",
                "score": 15
              },
              {
                "value": "innredet",
                "score": 15
              }
            ]
          }
        ],
        "properties": {
          "image": "https://akamai.vgc.no/v2/images/b7160592-ed52-45f9-89e3-56b250953855?fit=crop&format=auto&h=1796&w=1900&s=e1b780e35ee501ccf49d2deb62a403ea40f8b87b",
          "publishedTime": "2020-01-02T03:23:10Z",
          "description": "En båttur på åtte minutter fra Santorini tar deg 60 år tilbake i tid.",
          "type": "article",
          "title": "Thirassia – en tidsreise til Hellas før turistene"
        },
        "publishedTime": "2020-01-02T03:23:10Z",
        "hash": 11601912
      },
      "score": 0.7217711554811796
    },
    {
      "contentProfileRecord": {
        "@c": ".ContentProfileRecord",
        "key": "www.svd.se/a/d35e31bc-7c2c-3a95-81ae-ad1f0ee265e8/reseguide-aten",
        "updateTime": "2023-08-06T07:48:14.201Z",
        "expiryTime": "2023-08-13T07:48:14.201Z",
        "dataSource": "ADNUNTIUS",
        "network": {
          "id": "try_opt",
          "tagId": "b7462",
          "name": "C ALT",
          "url": "/api/v1/networks/try_opt"
        },
        "language": "sv",
        "keyValues": [
          {
            "key": "keywords",
            "values": [
              {
                "value": "aten",
                "score": 100
              },
              {
                "value": "a",
                "score": 100
              },
              {
                "value": "3a95",
                "score": 100
              },
              {
                "value": "d35e31bc",
                "score": 100
              },
              {
                "value": "ad1f0ee265e8",
                "score": 100
              },
              {
                "value": "konstitution",
                "score": 100
              },
              {
                "value": "7c2c",
                "score": 100
              },
              {
                "value": "81ae",
                "score": 100
              },
              {
                "value": "är",
                "score": 100
              },
              {
                "value": "reseguide",
                "score": 100
              }
            ]
          },
          {
            "key": "categories",
            "values": []
          },
          {
            "key": "words",
            "values": [
              {
                "value": "huvudstaden",
                "score": 15
              },
              {
                "value": "stad",
                "score": 15
              },
              {
                "value": "namn",
                "score": 15
              },
              {
                "value": "kvalitet",
                "score": 15
              },
              {
                "value": "dygnet",
                "score": 15
              },
              {
                "value": "åker",
                "score": 15
              },
              {
                "value": "krävde",
                "score": 15
              },
              {
                "value": "värd",
                "score": 15
              },
              {
                "value": "armar",
                "score": 15
              },
              {
                "value": "emot",
                "score": 15
              },
              {
                "value": "pandrosou",
                "score": 15
              },
              {
                "value": "torget",
                "score": 15
              },
              {
                "value": "folklivet",
                "score": 15
              },
              {
                "value": "skönhetsprodukter",
                "score": 15
              },
              {
                "value": "sprit",
                "score": 15
              },
              {
                "value": "hetsiga",
                "score": 15
              },
              {
                "value": "folket",
                "score": 15
              },
              {
                "value": "ungdomar",
                "score": 15
              },
              {
                "value": "glass",
                "score": 15
              },
              {
                "value": "nerför",
                "score": 15
              },
              {
                "value": "flirtar",
                "score": 15
              },
              {
                "value": "hänger",
                "score": 15
              },
              {
                "value": "svd",
                "score": 15
              },
              {
                "value": "antikviteter",
                "score": 15
              },
              {
                "value": "särskilt",
                "score": 15
              },
              {
                "value": "oortodoxt",
                "score": 15
              },
              {
                "value": "ansedd",
                "score": 15
              },
              {
                "value": "september",
                "score": 15
              },
              {
                "value": "grekland",
                "score": 15
              },
              {
                "value": "väg",
                "score": 15
              },
              {
                "value": "sötsaker",
                "score": 15
              },
              {
                "value": "ont",
                "score": 15
              },
              {
                "value": "grekiskt",
                "score": 15
              },
              {
                "value": "samlat",
                "score": 15
              },
              {
                "value": "dagliga",
                "score": 15
              },
              {
                "value": "land",
                "score": 15
              },
              {
                "value": "pulserar",
                "score": 15
              },
              {
                "value": "runt",
                "score": 15
              },
              {
                "value": "skateboard",
                "score": 15
              },
              {
                "value": "butiken",
                "score": 15
              },
              {
                "value": "förlovade",
                "score": 15
              },
              {
                "value": "honung",
                "score": 15
              },
              {
                "value": "öppna",
                "score": 15
              },
              {
                "value": "shoppingstråket",
                "score": 15
              },
              {
                "value": "gator",
                "score": 15
              },
              {
                "value": "uppror",
                "score": 15
              },
              {
                "value": "biltrafiken",
                "score": 15
              },
              {
                "value": "avstängd",
                "score": 15
              },
              {
                "value": "halvtaskig",
                "score": 15
              },
              {
                "value": "arkipelagen",
                "score": 15
              },
              {
                "value": "trappan",
                "score": 15
              },
              {
                "value": "titta",
                "score": 15
              },
              {
                "value": "säljer",
                "score": 15
              },
              {
                "value": "olivoljor",
                "score": 15
              },
              {
                "value": "resenären",
                "score": 15
              },
              {
                "value": "italiensk",
                "score": 15
              },
              {
                "value": "souvenirer",
                "score": 15
              },
              {
                "value": "gatan",
                "score": 15
              },
              {
                "value": "tar",
                "score": 15
              },
              {
                "value": "paradisöarnas",
                "score": 15
              }
            ]
          }
        ],
        "properties": {
          "image": "https://svd.vgc.no/v2/images/a45b46f0-6c38-4425-a396-7025fd7276d1?h=630&q=80&upscale=true&w=1200&s=2e5bbb9a11772fe4358036615986fbede57cf613",
          "publishedTime": "2010-04-15T04:00:59Z",
          "description": "Grekland är paradisöarnas förlovade land – och huvudstaden ansedd som ett ont måste på väg ut i arkipelagen. Men Aten är också en stad som tar emot resenären med öppna armar.",
          "type": "article",
          "title": "Reseguide: Aten | SvD"
        },
        "publishedTime": "2010-04-15T04:00:59Z",
        "hash": 11225998
      },
      "score": 0.7210267062691692
    },
    {
      "contentProfileRecord": {
        "@c": ".ContentProfileRecord",
        "key": "www.aftonbladet.se/nyheter/a/419xjE/kreta-langtar-efter-turisterna--men-an-ar-har-tomt-och-ode",
        "updateTime": "2023-08-11T13:13:31.703Z",
        "expiryTime": "2023-08-18T13:13:31.703Z",
        "dataSource": "ADNUNTIUS",
        "network": {
          "id": "try_opt",
          "tagId": "b7462",
          "name": "C ALT",
          "url": "/api/v1/networks/try_opt"
        },
        "language": "sv",
        "keyValues": [
          {
            "key": "keywords",
            "values": [
              {
                "value": "turister",
                "score": 100
              },
              {
                "value": "nyheter",
                "score": 100
              },
              {
                "value": "ellos",
                "score": 100
              },
              {
                "value": "är",
                "score": 100
              },
              {
                "value": "kreta",
                "score": 100
              },
              {
                "value": "langtar",
                "score": 100
              },
              {
                "value": "tomt",
                "score": 100
              },
              {
                "value": "öppna",
                "score": 100
              },
              {
                "value": "malmqvist",
                "score": 100
              },
              {
                "value": "just",
                "score": 100
              },
              {
                "value": "419xje",
                "score": 100
              },
              {
                "value": "precis",
                "score": 100
              },
              {
                "value": "grekiska",
                "score": 100
              },
              {
                "value": "matilda",
                "score": 100
              },
              {
                "value": "a",
                "score": 100
              },
              {
                "value": "turisterna",
                "score": 100
              },
              {
                "value": "både",
                "score": 100
              },
              {
                "value": "an",
                "score": 100
              },
              {
                "value": "håller",
                "score": 100
              },
              {
                "value": "populära",
                "score": 100
              },
              {
                "value": "hoppas",
                "score": 100
              },
              {
                "value": "ar",
                "score": 100
              },
              {
                "value": "ode",
                "score": 100
              },
              {
                "value": "grekland",
                "score": 100
              },
              {
                "value": "aprea",
                "score": 100
              },
              {
                "value": "ön",
                "score": 100
              },
              {
                "value": "väntar",
                "score": 100
              },
              {
                "value": "aftonbladet",
                "score": 100
              }
            ]
          },
          {
            "key": "categories",
            "values": [
              {
                "value": "IAB_16",
                "score": 100
              }
            ]
          },
          {
            "key": "words",
            "values": [
              {
                "value": "låda",
                "score": 15
              },
              {
                "value": "svenskar",
                "score": 15
              },
              {
                "value": "överallt",
                "score": 15
              },
              {
                "value": "immar",
                "score": 15
              },
              {
                "value": "turistsäsongen",
                "score": 15
              },
              {
                "value": "coronapandemin",
                "score": 15
              },
              {
                "value": "värst",
                "score": 15
              },
              {
                "value": "noggranna",
                "score": 15
              },
              {
                "value": "blanka",
                "score": 15
              },
              {
                "value": "hotell",
                "score": 15
              },
              {
                "value": "extern",
                "score": 15
              },
              {
                "value": "stängda",
                "score": 15
              },
              {
                "value": "klockan",
                "score": 15
              },
              {
                "value": "når",
                "score": 15
              },
              {
                "value": "erkänna",
                "score": 15
              },
              {
                "value": "öarna",
                "score": 15
              },
              {
                "value": "annars",
                "score": 15
              },
              {
                "value": "lokalborna",
                "score": 15
              },
              {
                "value": "ställa",
                "score": 15
              },
              {
                "value": "tog",
                "score": 15
              },
              {
                "value": "säker",
                "score": 15
              },
              {
                "value": "låg",
                "score": 15
              },
              {
                "value": "väntan",
                "score": 15
              },
              {
                "value": "kunder",
                "score": 15
              },
              {
                "value": "nästan",
                "score": 15
              },
              {
                "value": "juni",
                "score": 15
              },
              {
                "value": "sommar",
                "score": 15
              },
              {
                "value": "livliga",
                "score": 15
              },
              {
                "value": "lackar",
                "score": 15
              },
              {
                "value": "munskyddskrav",
                "score": 15
              },
              {
                "value": "boka",
                "score": 15
              },
              {
                "value": "resa",
                "score": 15
              },
              {
                "value": "längtar",
                "score": 15
              },
              {
                "value": "färskt",
                "score": 15
              },
              {
                "value": "skälet",
                "score": 15
              },
              {
                "value": "införs",
                "score": 15
              },
              {
                "value": "reser",
                "score": 15
              },
              {
                "value": "krävs",
                "score": 15
              },
              {
                "value": "sol",
                "score": 15
              },
              {
                "value": "amsterdam",
                "score": 15
              },
              {
                "value": "rabatt",
                "score": 15
              },
              {
                "value": "fall",
                "score": 15
              },
              {
                "value": "slutdestinationen",
                "score": 15
              },
              {
                "value": "shapingeffekt",
                "score": 15
              },
              {
                "value": "riktigt",
                "score": 15
              },
              {
                "value": "publicerad",
                "score": 15
              },
              {
                "value": "bor",
                "score": 15
              },
              {
                "value": "tom",
                "score": 15
              },
              {
                "value": "restaurangerna",
                "score": 15
              },
              {
                "value": "biluthyrare",
                "score": 15
              },
              {
                "value": "biluthyraren",
                "score": 15
              },
              {
                "value": "regelbundet",
                "score": 15
              },
              {
                "value": "levt",
                "score": 15
              },
              {
                "value": "människor",
                "score": 15
              },
              {
                "value": "turistgatorna",
                "score": 15
              },
              {
                "value": "direkt",
                "score": 15
              },
              {
                "value": "stockholm",
                "score": 15
              },
              {
                "value": "parningsläten",
                "score": 15
              },
              {
                "value": "städare",
                "score": 15
              },
              {
                "value": "gassande",
                "score": 15
              },
              {
                "value": "desinficeras",
                "score": 15
              },
              {
                "value": "baddräkter",
                "score": 15
              },
              {
                "value": "färger",
                "score": 15
              },
              {
                "value": "betydligt",
                "score": 15
              },
              {
                "value": "aten",
                "score": 15
              },
              {
                "value": "nuläget",
                "score": 15
              },
              {
                "value": "uppdaterad",
                "score": 15
              },
              {
                "value": "köp",
                "score": 15
              },
              {
                "value": "stränder",
                "score": 15
              },
              {
                "value": "slår",
                "score": 15
              },
              {
                "value": "flygplan",
                "score": 15
              },
              {
                "value": "nätterna",
                "score": 15
              },
              {
                "value": "tömda",
                "score": 15
              },
              {
                "value": "öde",
                "score": 15
              },
              {
                "value": "världen",
                "score": 15
              },
              {
                "value": "beskedet",
                "score": 15
              },
              {
                "value": "metodiskt",
                "score": 15
              },
              {
                "value": "ganska",
                "score": 15
              },
              {
                "value": "stränderna",
                "score": 15
              },
              {
                "value": "mellanlandningar",
                "score": 15
              },
              {
                "value": "flyga",
                "score": 15
              },
              {
                "value": "storstäderna",
                "score": 15
              },
              {
                "value": "länk",
                "score": 15
              },
              {
                "value": "pcr",
                "score": 15
              },
              {
                "value": "tjimmande",
                "score": 15
              },
              {
                "value": "striktare",
                "score": 15
              },
              {
                "value": "resan",
                "score": 15
              },
              {
                "value": "resmål",
                "score": 15
              },
              {
                "value": "restriktioner",
                "score": 15
              },
              {
                "value": "faktiskt",
                "score": 15
              },
              {
                "value": "daily",
                "score": 15
              },
              {
                "value": "vet",
                "score": 15
              },
              {
                "value": "tar",
                "score": 15
              },
              {
                "value": "värmebölja",
                "score": 15
              },
              {
                "value": "maj",
                "score": 15
              },
              {
                "value": "lågsäsong",
                "score": 15
              },
              {
                "value": "fåtal",
                "score": 15
              },
              {
                "value": "landar",
                "score": 15
              },
              {
                "value": "igång",
                "score": 15
              },
              {
                "value": "fotnot",
                "score": 15
              },
              {
                "value": "tempen",
                "score": 15
              },
              {
                "value": "fina",
                "score": 15
              },
              {
                "value": "folk",
                "score": 15
              },
              {
                "value": "halvår",
                "score": 15
              },
              {
                "value": "testat",
                "score": 15
              },
              {
                "value": "visa",
                "score": 15
              },
              {
                "value": "stolarna",
                "score": 15
              },
              {
                "value": "coronapassen",
                "score": 15
              },
              {
                "value": "tålmodigt",
                "score": 15
              },
              {
                "value": "smittan",
                "score": 15
              },
              {
                "value": "återvända",
                "score": 15
              },
              {
                "value": "kväkande",
                "score": 15
              },
              {
                "value": "grodorna",
                "score": 15
              },
              {
                "value": "smittspridningen",
                "score": 15
              },
              {
                "value": "eventuella",
                "score": 15
              },
              {
                "value": "graders",
                "score": 15
              },
              {
                "value": "spanien",
                "score": 15
              },
              {
                "value": "bikinis",
                "score": 15
              },
              {
                "value": "kliver",
                "score": 15
              },
              {
                "value": "hos",
                "score": 15
              },
              {
                "value": "hela",
                "score": 15
              },
              {
                "value": "sveper",
                "score": 15
              },
              {
                "value": "hotellreceptionisten",
                "score": 15
              },
              {
                "value": "år",
                "score": 15
              },
              {
                "value": "ser",
                "score": 15
              },
              {
                "value": "resmålet",
                "score": 15
              },
              {
                "value": "skinande",
                "score": 15
              },
              {
                "value": "undantagen",
                "score": 15
              },
              {
                "value": "kontroller",
                "score": 15
              },
              {
                "value": "ansvariga",
                "score": 15
              },
              {
                "value": "ordning",
                "score": 15
              },
              {
                "value": "tjoande",
                "score": 15
              },
              {
                "value": "veta",
                "score": 15
              },
              {
                "value": "framemot",
                "score": 15
              },
              {
                "value": "hälsointyg",
                "score": 15
              },
              {
                "value": "mellanrum",
                "score": 15
              },
              {
                "value": "medelhavet",
                "score": 15
              },
              {
                "value": "ensam",
                "score": 15
              },
              {
                "value": "krav",
                "score": 15
              },
              {
                "value": "timmar",
                "score": 15
              },
              {
                "value": "golvet",
                "score": 15
              },
              {
                "value": "vågar",
                "score": 15
              },
              {
                "value": "känns",
                "score": 15
              },
              {
                "value": "varje",
                "score": 15
              },
              {
                "value": "munskydd",
                "score": 15
              },
              {
                "value": "trots",
                "score": 15
              },
              {
                "value": "stranden",
                "score": 15
              },
              {
                "value": "håll",
                "score": 15
              },
              {
                "value": "invånarna",
                "score": 15
              },
              {
                "value": "påfrestande",
                "score": 15
              },
              {
                "value": "ö",
                "score": 15
              },
              {
                "value": "exakt",
                "score": 15
              },
              {
                "value": "föredra",
                "score": 15
              },
              {
                "value": "svetten",
                "score": 15
              },
              {
                "value": "ivrigt",
                "score": 15
              },
              {
                "value": "klaga",
                "score": 15
              },
              {
                "value": "lämnat",
                "score": 15
              },
              {
                "value": "beror",
                "score": 15
              },
              {
                "value": "parasollen",
                "score": 15
              },
              {
                "value": "sak",
                "score": 15
              },
              {
                "value": "utöver",
                "score": 15
              },
              {
                "value": "värme",
                "score": 15
              },
              {
                "value": "skyldiga",
                "score": 15
              },
              {
                "value": "hetta",
                "score": 15
              },
              {
                "value": "möttes",
                "score": 15
              },
              {
                "value": "flygplatsen",
                "score": 15
              },
              {
                "value": "öppnar",
                "score": 15
              },
              {
                "value": "se",
                "score": 15
              },
              {
                "value": "vaken",
                "score": 15
              },
              {
                "value": "fastlandet",
                "score": 15
              },
              {
                "value": "drar",
                "score": 15
              },
              {
                "value": "midja",
                "score": 15
              },
              {
                "value": "krånglade",
                "score": 15
              },
              {
                "value": "står",
                "score": 15
              },
              {
                "value": "framförallt",
                "score": 15
              },
              {
                "value": "väg",
                "score": 15
              },
              {
                "value": "andan",
                "score": 15
              },
              {
                "value": "ytterst",
                "score": 15
              },
              {
                "value": "landet",
                "score": 15
              },
              {
                "value": "strandpersonalen",
                "score": 15
              },
              {
                "value": "klarhet",
                "score": 15
              },
              {
                "value": "lördagen",
                "score": 15
              },
              {
                "value": "spöklikt",
                "score": 15
              },
              {
                "value": "iväg",
                "score": 15
              },
              {
                "value": "istället",
                "score": 15
              },
              {
                "value": "mallorca",
                "score": 15
              },
              {
                "value": "allra",
                "score": 15
              },
              {
                "value": "menar",
                "score": 15
              },
              {
                "value": "test",
                "score": 15
              },
              {
                "value": "äntligen",
                "score": 15
              },
              {
                "value": "kort",
                "score": 15
              },
              {
                "value": "meters",
                "score": 15
              },
              {
                "value": "titta",
                "score": 15
              },
              {
                "value": "solglasögonen",
                "score": 15
              },
              {
                "value": "vaccinationerna",
                "score": 15
              },
              {
                "value": "tänkt",
                "score": 15
              },
              {
                "value": "kvällen",
                "score": 15
              },
              {
                "value": "lokalbor",
                "score": 15
              },
              {
                "value": "reporter",
                "score": 15
              }
            ]
          }
        ],
        "properties": {
          "image": "https://images.aftonbladet-cdn.se/v2/images/5effecf1-73f4-41de-97fb-9e6a38f6a3bd?fit=crop&format=auto&h=628&q=50&w=1200&s=38e05059b72a385d34447c5c55136c1d650affe4",
          "publishedTime": "2021-05-09T07:40:04Z",
          "description": "KRETA. Vågar man boka en resa till Grekland?  Ja, menar i alla fall invånarna på Kreta, som ivrigt väntar på att turiste",
          "type": "article",
          "title": "Ett öde Kreta längtar efter turisterna"
        },
        "publishedTime": "2021-05-09T07:40:04Z",
        "hash": 12177311
      },
      "score": 0.7190102298993871
    },
    {
      "contentProfileRecord": {
        "@c": ".ContentProfileRecord",
        "key": "www.aftonbladet.se/resa/a/8wJknW/har-kryper-grekerna-till-korset",
        "updateTime": "2023-08-08T17:06:48.390Z",
        "expiryTime": "2023-08-15T17:06:48.390Z",
        "dataSource": "ADNUNTIUS",
        "network": {
          "id": "try_opt",
          "tagId": "b7462",
          "name": "C ALT",
          "url": "/api/v1/networks/try_opt"
        },
        "language": "sv",
        "keyValues": [
          {
            "key": "keywords",
            "values": [
              {
                "value": "hela",
                "score": 100
              },
              {
                "value": "hotels.com",
                "score": 100
              },
              {
                "value": "natur",
                "score": 100
              },
              {
                "value": "greker",
                "score": 100
              },
              {
                "value": "8wjknw",
                "score": 100
              },
              {
                "value": "sägs",
                "score": 100
              },
              {
                "value": "vägen",
                "score": 100
              },
              {
                "value": "är",
                "score": 100
              },
              {
                "value": "pittoreska",
                "score": 100
              },
              {
                "value": "mirakel",
                "score": 100
              },
              {
                "value": "blicken",
                "score": 100
              },
              {
                "value": "hand",
                "score": 100
              },
              {
                "value": "kyrkan",
                "score": 100
              },
              {
                "value": "a",
                "score": 100
              },
              {
                "value": "tinos",
                "score": 100
              },
              {
                "value": "stad",
                "score": 100
              },
              {
                "value": "grekland",
                "score": 100
              },
              {
                "value": "stränder",
                "score": 100
              },
              {
                "value": "resa",
                "score": 100
              },
              {
                "value": "ön",
                "score": 100
              },
              {
                "value": "evangelistra",
                "score": 100
              },
              {
                "value": "korset",
                "score": 100
              },
              {
                "value": "grekerna",
                "score": 100
              },
              {
                "value": "kryper",
                "score": 100
              },
              {
                "value": "tarabados",
                "score": 100
              }
            ]
          },
          {
            "key": "categories",
            "values": []
          },
          {
            "key": "words",
            "values": [
              {
                "value": "besöks",
                "score": 15
              },
              {
                "value": "blått",
                "score": 15
              },
              {
                "value": "duvslagen",
                "score": 15
              },
              {
                "value": "vackra",
                "score": 15
              },
              {
                "value": "grönt",
                "score": 15
              },
              {
                "value": "gamla",
                "score": 15
              },
              {
                "value": "åldriga",
                "score": 15
              },
              {
                "value": "målmedvetet",
                "score": 15
              },
              {
                "value": "skillnad",
                "score": 15
              },
              {
                "value": "vackrare",
                "score": 15
              },
              {
                "value": "kolymbithra",
                "score": 15
              },
              {
                "value": "botanisera",
                "score": 15
              },
              {
                "value": "fåtal",
                "score": 15
              },
              {
                "value": "undantag",
                "score": 15
              },
              {
                "value": "bota",
                "score": 15
              },
              {
                "value": "moped",
                "score": 15
              },
              {
                "value": "turismen",
                "score": 15
              },
              {
                "value": "klart",
                "score": 15
              },
              {
                "value": "koumaros",
                "score": 15
              },
              {
                "value": "extern",
                "score": 15
              },
              {
                "value": "krypandet",
                "score": 15
              },
              {
                "value": "benen",
                "score": 15
              },
              {
                "value": "öarna",
                "score": 15
              },
              {
                "value": "tarabadosområdet",
                "score": 15
              },
              {
                "value": "grön",
                "score": 15
              },
              {
                "value": "vattnet",
                "score": 15
              },
              {
                "value": "ruiner",
                "score": 15
              },
              {
                "value": "hos",
                "score": 15
              },
              {
                "value": "orkar",
                "score": 15
              },
              {
                "value": "utländsk",
                "score": 15
              },
              {
                "value": "anstränger",
                "score": 15
              },
              {
                "value": "stycken",
                "score": 15
              },
              {
                "value": "smala",
                "score": 15
              },
              {
                "value": "år",
                "score": 15
              },
              {
                "value": "slipper",
                "score": 15
              },
              {
                "value": "mat",
                "score": 15
              },
              {
                "value": "paradisiska",
                "score": 15
              },
              {
                "value": "kö",
                "score": 15
              },
              {
                "value": "bil",
                "score": 15
              },
              {
                "value": "nästan",
                "score": 15
              },
              {
                "value": "genuin",
                "score": 15
              },
              {
                "value": "stenhus",
                "score": 15
              },
              {
                "value": "rinnande",
                "score": 15
              },
              {
                "value": "backen",
                "score": 15
              },
              {
                "value": "grekisk",
                "score": 15
              },
              {
                "value": "turism",
                "score": 15
              },
              {
                "value": "hotellen",
                "score": 15
              },
              {
                "value": "besynnerlig",
                "score": 15
              },
              {
                "value": "finkornig",
                "score": 15
              },
              {
                "value": "boka",
                "score": 15
              },
              {
                "value": "besöka",
                "score": 15
              },
              {
                "value": "helande",
                "score": 15
              },
              {
                "value": "kardiani",
                "score": 15
              },
              {
                "value": "sevärda",
                "score": 15
              },
              {
                "value": "ryggen",
                "score": 15
              },
              {
                "value": "fantastisk",
                "score": 15
              },
              {
                "value": "berömda",
                "score": 15
              },
              {
                "value": "andas",
                "score": 15
              },
              {
                "value": "upplevelse",
                "score": 15
              },
              {
                "value": "opåverkad",
                "score": 15
              },
              {
                "value": "krafter",
                "score": 15
              },
              {
                "value": "ända",
                "score": 15
              },
              {
                "value": "sanden",
                "score": 15
              },
              {
                "value": "volax",
                "score": 15
              },
              {
                "value": "gillar",
                "score": 15
              },
              {
                "value": "upptäcka",
                "score": 15
              },
              {
                "value": "ikonen",
                "score": 15
              },
              {
                "value": "annanstans",
                "score": 15
              },
              {
                "value": "nå",
                "score": 15
              },
              {
                "value": "öar",
                "score": 15
              },
              {
                "value": "entusiasterna",
                "score": 15
              },
              {
                "value": "vissa",
                "score": 15
              },
              {
                "value": "dregla",
                "score": 15
              },
              {
                "value": "känns",
                "score": 15
              },
              {
                "value": "älskar",
                "score": 15
              },
              {
                "value": "kyrka",
                "score": 15
              },
              {
                "value": "kämpar",
                "score": 15
              },
              {
                "value": "öns",
                "score": 15
              },
              {
                "value": "ståendes",
                "score": 15
              },
              {
                "value": "riktigt",
                "score": 15
              },
              {
                "value": "publicerad",
                "score": 15
              },
              {
                "value": "varje",
                "score": 15
              },
              {
                "value": "vars",
                "score": 15
              },
              {
                "value": "kysser",
                "score": 15
              },
              {
                "value": "finaste",
                "score": 15
              },
              {
                "value": "ben",
                "score": 15
              },
              {
                "value": "framme",
                "score": 15
              },
              {
                "value": "köa",
                "score": 15
              },
              {
                "value": "bästa",
                "score": 15
              },
              {
                "value": "tusentals",
                "score": 15
              },
              {
                "value": "hittar",
                "score": 15
              },
              {
                "value": "kantade",
                "score": 15
              },
              {
                "value": "byar",
                "score": 15
              },
              {
                "value": "ikon",
                "score": 15
              },
              {
                "value": "ljus",
                "score": 15
              },
              {
                "value": "byn",
                "score": 15
              },
              {
                "value": "ö",
                "score": 15
              },
              {
                "value": "otur",
                "score": 15
              },
              {
                "value": "kristallklart",
                "score": 15
              },
              {
                "value": "svetten",
                "score": 15
              },
              {
                "value": "duvslag",
                "score": 15
              },
              {
                "value": "hör",
                "score": 15
              },
              {
                "value": "stannar",
                "score": 15
              },
              {
                "value": "tunga",
                "score": 15
              },
              {
                "value": "hängivne",
                "score": 15
              },
              {
                "value": "kraft",
                "score": 15
              },
              {
                "value": "skiftar",
                "score": 15
              },
              {
                "value": "besökarna",
                "score": 15
              },
              {
                "value": "välkommen",
                "score": 15
              },
              {
                "value": "grekiska",
                "score": 15
              },
              {
                "value": "vandraren",
                "score": 15
              },
              {
                "value": "korstecken",
                "score": 15
              },
              {
                "value": "panagia",
                "score": 15
              },
              {
                "value": "allehanda",
                "score": 15
              },
              {
                "value": "väl",
                "score": 15
              },
              {
                "value": "alldeles",
                "score": 15
              },
              {
                "value": "km",
                "score": 15
              },
              {
                "value": "kykladerna",
                "score": 15
              },
              {
                "value": "uppleva",
                "score": 15
              },
              {
                "value": "religiöst",
                "score": 15
              },
              {
                "value": "heliga",
                "score": 15
              },
              {
                "value": "charter",
                "score": 15
              },
              {
                "value": "uppdaterad",
                "score": 15
              },
              {
                "value": "frodig",
                "score": 15
              },
              {
                "value": "drömresa",
                "score": 15
              },
              {
                "value": "trakta",
                "score": 15
              },
              {
                "value": "sakta",
                "score": 15
              },
              {
                "value": "sjukdomar",
                "score": 15
              },
              {
                "value": "sevärdhet",
                "score": 15
              },
              {
                "value": "mål",
                "score": 15
              },
              {
                "value": "utföra",
                "score": 15
              },
              {
                "value": "husmanskost",
                "score": 15
              },
              {
                "value": "krypare",
                "score": 15
              },
              {
                "value": "nått",
                "score": 15
              },
              {
                "value": "bär",
                "score": 15
              },
              {
                "value": "ktikados",
                "score": 15
              },
              {
                "value": "underbara",
                "score": 15
              },
              {
                "value": "majestätiska",
                "score": 15
              },
              {
                "value": "ena",
                "score": 15
              },
              {
                "value": "krypa",
                "score": 15
              },
              {
                "value": "runt",
                "score": 15
              },
              {
                "value": "egen",
                "score": 15
              },
              {
                "value": "länk",
                "score": 15
              },
              {
                "value": "vardagslivet",
                "score": 15
              },
              {
                "value": "åter",
                "score": 15
              },
              {
                "value": "förödande",
                "score": 15
              },
              {
                "value": "hitta",
                "score": 15
              },
              {
                "value": "tur",
                "score": 15
              },
              {
                "value": "bokstavligen",
                "score": 15
              },
              {
                "value": "välkomnande",
                "score": 15
              },
              {
                "value": "gåta",
                "score": 15
              },
              {
                "value": "kort",
                "score": 15
              },
              {
                "value": "kryparna",
                "score": 15
              },
              {
                "value": "sannerligen",
                "score": 15
              },
              {
                "value": "landskapet",
                "score": 15
              },
              {
                "value": "anses",
                "score": 15
              },
              {
                "value": "ateist",
                "score": 15
              },
              {
                "value": "gränder",
                "score": 15
              },
              {
                "value": "spännande",
                "score": 15
              },
              {
                "value": "däremot",
                "score": 15
              },
              {
                "value": "tänder",
                "score": 15
              }
            ]
          }
        ],
        "properties": {
          "image": "https://images.aftonbladet-cdn.se/v2/images/6a8b7ee8-de8e-4019-94fa-e2f88f9442da?fit=crop&format=auto&h=250&q=50&w=365&s=0b6e5ff7e3ac783a5cda0bed48749e64f16959ab",
          "publishedTime": "2004-06-05T21:59Z",
          "description": "På Tinos kommer det grekiska vardagslivet i första hand, turismen i andra. Hit kryper - bokstavligen - många greker för",
          "type": "article",
          "title": "Här kryper grekerna till korset"
        },
        "publishedTime": "2004-06-05T21:59:00Z",
        "hash": 9370995
      },
      "score": 0.7158489602286924
    },
    {
      "contentProfileRecord": {
        "@c": ".ContentProfileRecord",
        "key": "www.vg.no/forbruker/reise/i/oRKr1B/10-greske-hopp",
        "updateTime": "2023-08-07T17:14:56.298Z",
        "expiryTime": "2023-08-14T17:14:56.298Z",
        "dataSource": "ADNUNTIUS",
        "network": {
          "id": "try_opt",
          "tagId": "b7462",
          "name": "C ALT",
          "url": "/api/v1/networks/try_opt"
        },
        "language": "no",
        "keyValues": [
          {
            "key": "keywords",
            "values": [
              {
                "value": "øy",
                "score": 100
              },
              {
                "value": "turister",
                "score": 100
              },
              {
                "value": "øya",
                "score": 100
              },
              {
                "value": "reiser",
                "score": 100
              },
              {
                "value": "preget",
                "score": 100
              },
              {
                "value": "kykladenes",
                "score": 100
              },
              {
                "value": "greske",
                "score": 100
              },
              {
                "value": "turistene",
                "score": 100
              },
              {
                "value": "sandstrendene",
                "score": 100
              },
              {
                "value": "heller",
                "score": 100
              },
              {
                "value": "største",
                "score": 100
              },
              {
                "value": "sjarmen",
                "score": 100
              },
              {
                "value": "hellas",
                "score": 100
              },
              {
                "value": "fine",
                "score": 100
              },
              {
                "value": "by",
                "score": 100
              },
              {
                "value": "kirker",
                "score": 100
              },
              {
                "value": "reise",
                "score": 100
              },
              {
                "value": "paros",
                "score": 100
              },
              {
                "value": "flere",
                "score": 100
              },
              {
                "value": "rabatt",
                "score": 100
              },
              {
                "value": "sjarm",
                "score": 100
              },
              {
                "value": "kr",
                "score": 100
              },
              {
                "value": "store",
                "score": 100
              },
              {
                "value": "stor",
                "score": 100
              },
              {
                "value": "hopp",
                "score": 100
              },
              {
                "value": "forbruker",
                "score": 100
              },
              {
                "value": "orkr1b",
                "score": 100
              },
              {
                "value": "kyklader",
                "score": 100
              },
              {
                "value": "hvite",
                "score": 100
              },
              {
                "value": "kykladene",
                "score": 100
              },
              {
                "value": "sandstrender",
                "score": 100
              }
            ]
          },
          {
            "key": "categories",
            "values": []
          },
          {
            "key": "words",
            "values": [
              {
                "value": "familier",
                "score": 15
              },
              {
                "value": "nett",
                "score": 15
              },
              {
                "value": "ukens",
                "score": 15
              },
              {
                "value": "frihet",
                "score": 15
              },
              {
                "value": "høyeste",
                "score": 15
              },
              {
                "value": "fjellrike",
                "score": 15
              },
              {
                "value": "knutepunkt",
                "score": 15
              },
              {
                "value": "trafikken",
                "score": 15
              },
              {
                "value": "avstand",
                "score": 15
              },
              {
                "value": "rabattkode",
                "score": 15
              },
              {
                "value": "ferie",
                "score": 15
              },
              {
                "value": "nordligste",
                "score": 15
              },
              {
                "value": "bydelen",
                "score": 15
              },
              {
                "value": "opptil",
                "score": 15
              },
              {
                "value": "klippene",
                "score": 15
              },
              {
                "value": "hånd",
                "score": 15
              },
              {
                "value": "likevel",
                "score": 15
              },
              {
                "value": "kupler",
                "score": 15
              },
              {
                "value": "vakker",
                "score": 15
              },
              {
                "value": "fredelig",
                "score": 15
              },
              {
                "value": "vestlige",
                "score": 15
              },
              {
                "value": "stalagmitter",
                "score": 15
              },
              {
                "value": "vulkanske",
                "score": 15
              },
              {
                "value": "strømmer",
                "score": 15
              },
              {
                "value": "besøk",
                "score": 15
              },
              {
                "value": "gir",
                "score": 15
              },
              {
                "value": "kunstnere",
                "score": 15
              },
              {
                "value": "olivenlunder",
                "score": 15
              },
              {
                "value": "nord",
                "score": 15
              },
              {
                "value": "gangbro",
                "score": 15
              },
              {
                "value": "tillegg",
                "score": 15
              },
              {
                "value": "nest",
                "score": 15
              },
              {
                "value": "naoussa",
                "score": 15
              },
              {
                "value": "hovedbyen",
                "score": 15
              },
              {
                "value": "jetsettere",
                "score": 15
              },
              {
                "value": "antiparos",
                "score": 15
              },
              {
                "value": "bygget",
                "score": 15
              },
              {
                "value": "postkortene",
                "score": 15
              },
              {
                "value": "administrative",
                "score": 15
              },
              {
                "value": "kritthvitt",
                "score": 15
              },
              {
                "value": "sitrushager",
                "score": 15
              },
              {
                "value": "form",
                "score": 15
              },
              {
                "value": "bussruter",
                "score": 15
              },
              {
                "value": "naxos",
                "score": 15
              },
              {
                "value": "ermoupolis",
                "score": 15
              },
              {
                "value": "få",
                "score": 15
              },
              {
                "value": "tross",
                "score": 15
              },
              {
                "value": "o.h",
                "score": 15
              },
              {
                "value": "nok",
                "score": 15
              },
              {
                "value": "kapeller",
                "score": 15
              },
              {
                "value": "gratis",
                "score": 15
              },
              {
                "value": "øyene",
                "score": 15
              },
              {
                "value": "lillebroren",
                "score": 15
              },
              {
                "value": "avslappet",
                "score": 15
              },
              {
                "value": "intellektuelle",
                "score": 15
              },
              {
                "value": "øyhoppere",
                "score": 15
              },
              {
                "value": "lofferne",
                "score": 15
              },
              {
                "value": "godt",
                "score": 15
              },
              {
                "value": "gamle",
                "score": 15
              },
              {
                "value": "kjøp",
                "score": 15
              },
              {
                "value": "akkurat",
                "score": 15
              },
              {
                "value": "høye",
                "score": 15
              },
              {
                "value": "stalaktitter",
                "score": 15
              },
              {
                "value": "strender",
                "score": 15
              },
              {
                "value": "fjell",
                "score": 15
              },
              {
                "value": "meter",
                "score": 15
              },
              {
                "value": "grunn",
                "score": 15
              },
              {
                "value": "opplevelser",
                "score": 15
              },
              {
                "value": "hit",
                "score": 15
              },
              {
                "value": "populære",
                "score": 15
              },
              {
                "value": "atmosfære",
                "score": 15
              },
              {
                "value": "dra",
                "score": 15
              },
              {
                "value": "behagelig",
                "score": 15
              },
              {
                "value": "større",
                "score": 15
              },
              {
                "value": "hule",
                "score": 15
              },
              {
                "value": "fotturister",
                "score": 15
              },
              {
                "value": "kjent",
                "score": 15
              },
              {
                "value": "avsvidd",
                "score": 15
              },
              {
                "value": "juli",
                "score": 15
              },
              {
                "value": "klynger",
                "score": 15
              },
              {
                "value": "møtested",
                "score": 15
              },
              {
                "value": "magnet",
                "score": 15
              },
              {
                "value": "spar",
                "score": 15
              },
              {
                "value": "fiskebyen",
                "score": 15
              },
              {
                "value": "lille",
                "score": 15
              },
              {
                "value": "dager",
                "score": 15
              },
              {
                "value": "ungdommer",
                "score": 15
              },
              {
                "value": "hellige",
                "score": 15
              },
              {
                "value": "mest",
                "score": 15
              },
              {
                "value": "tørr",
                "score": 15
              },
              {
                "value": "velstående",
                "score": 15
              },
              {
                "value": "vanskelig",
                "score": 15
              },
              {
                "value": "tinos",
                "score": 15
              },
              {
                "value": "mengde",
                "score": 15
              },
              {
                "value": "tidligere",
                "score": 15
              },
              {
                "value": "aktive",
                "score": 15
              },
              {
                "value": "forbeholdt",
                "score": 15
              },
              {
                "value": "august",
                "score": 15
              },
              {
                "value": "stille",
                "score": 15
              },
              {
                "value": "\uD83D\uDDA4",
                "score": 15
              },
              {
                "value": "svar",
                "score": 15
              },
              {
                "value": "slett",
                "score": 15
              },
              {
                "value": "solnedgangen",
                "score": 15
              },
              {
                "value": "m",
                "score": 15
              },
              {
                "value": "hotelltilbud",
                "score": 15
              },
              {
                "value": "finne",
                "score": 15
              },
              {
                "value": "del",
                "score": 15
              },
              {
                "value": "tiltrukket",
                "score": 15
              },
              {
                "value": "engelske",
                "score": 15
              },
              {
                "value": "byen",
                "score": 15
              },
              {
                "value": "forbundet",
                "score": 15
              },
              {
                "value": "finnes",
                "score": 15
              },
              {
                "value": "hovedattraksjonen",
                "score": 15
              },
              {
                "value": "parikia",
                "score": 15
              },
              {
                "value": "barer",
                "score": 15
              },
              {
                "value": "zas",
                "score": 15
              },
              {
                "value": "homofile",
                "score": 15
              },
              {
                "value": "oversvømt",
                "score": 15
              },
              {
                "value": "får",
                "score": 15
              },
              {
                "value": "reiseselskaper",
                "score": 15
              },
              {
                "value": "blanding",
                "score": 15
              },
              {
                "value": "thira",
                "score": 15
              },
              {
                "value": "antikkens",
                "score": 15
              },
              {
                "value": "praktfull",
                "score": 15
              },
              {
                "value": "nesten",
                "score": 15
              },
              {
                "value": "vg",
                "score": 15
              },
              {
                "value": "innseilingen",
                "score": 15
              },
              {
                "value": "år",
                "score": 15
              },
              {
                "value": "helligdagene",
                "score": 15
              },
              {
                "value": "bookbeat",
                "score": 15
              },
              {
                "value": "ser",
                "score": 15
              },
              {
                "value": "fineste",
                "score": 15
              },
              {
                "value": "fiskehavnen",
                "score": 15
              },
              {
                "value": "total",
                "score": 15
              },
              {
                "value": "blant",
                "score": 15
              },
              {
                "value": "stier",
                "score": 15
              },
              {
                "value": "stadig",
                "score": 15
              },
              {
                "value": "marmorportalen",
                "score": 15
              },
              {
                "value": "reisekupp",
                "score": 15
              },
              {
                "value": "landsbyer",
                "score": 15
              },
              {
                "value": "klipper",
                "score": 15
              },
              {
                "value": "ios",
                "score": 15
              },
              {
                "value": "seks",
                "score": 15
              },
              {
                "value": "halvøya",
                "score": 15
              },
              {
                "value": "turistsentrum",
                "score": 15
              },
              {
                "value": "rimelig",
                "score": 15
              },
              {
                "value": "natteliv",
                "score": 15
              },
              {
                "value": "alt",
                "score": 15
              },
              {
                "value": "hulehus",
                "score": 15
              },
              {
                "value": "hvert",
                "score": 15
              },
              {
                "value": "heter",
                "score": 15
              },
              {
                "value": "ferd",
                "score": 15
              },
              {
                "value": "palatia",
                "score": 15
              },
              {
                "value": "sør",
                "score": 15
              },
              {
                "value": "yngste",
                "score": 15
              },
              {
                "value": "fergetrafikken",
                "score": 15
              },
              {
                "value": "nummer",
                "score": 15
              },
              {
                "value": "blå",
                "score": 15
              },
              {
                "value": "øyer",
                "score": 15
              },
              {
                "value": "enkel",
                "score": 15
              },
              {
                "value": "mnd",
                "score": 15
              },
              {
                "value": "svarte",
                "score": 15
              },
              {
                "value": "santorini",
                "score": 15
              },
              {
                "value": "diskoteker",
                "score": 15
              },
              {
                "value": "spesielt",
                "score": 15
              },
              {
                "value": "pakketur",
                "score": 15
              },
              {
                "value": "fjellet",
                "score": 15
              },
              {
                "value": "athen",
                "score": 15
              },
              {
                "value": "hovedstaden",
                "score": 15
              },
              {
                "value": "overnatting",
                "score": 15
              },
              {
                "value": "verdt",
                "score": 15
              },
              {
                "value": "nettverk",
                "score": 15
              },
              {
                "value": "uforglemmelig",
                "score": 15
              },
              {
                "value": "senteret",
                "score": 15
              },
              {
                "value": "pilegrimer",
                "score": 15
              },
              {
                "value": "høysesongen",
                "score": 15
              },
              {
                "value": "gjort",
                "score": 15
              },
              {
                "value": "tradisjonelle",
                "score": 15
              },
              {
                "value": "prøv",
                "score": 15
              },
              {
                "value": "blush",
                "score": 15
              },
              {
                "value": "sentrale",
                "score": 15
              },
              {
                "value": "hus",
                "score": 15
              },
              {
                "value": "lang",
                "score": 15
              },
              {
                "value": "frodig",
                "score": 15
              },
              {
                "value": "uberørte",
                "score": 15
              },
              {
                "value": "bevart",
                "score": 15
              },
              {
                "value": "turisttrafikk",
                "score": 15
              },
              {
                "value": "katolske",
                "score": 15
              },
              {
                "value": "kulturelle",
                "score": 15
              },
              {
                "value": "masseturisme",
                "score": 15
              },
              {
                "value": "viaplay",
                "score": 15
              },
              {
                "value": "mykonos",
                "score": 15
              },
              {
                "value": "ibiza",
                "score": 15
              },
              {
                "value": "egen",
                "score": 15
              },
              {
                "value": "utforske",
                "score": 15
              },
              {
                "value": "kystlinjen",
                "score": 15
              },
              {
                "value": "\uD83D\uDC4F\uD83C\uDFFB",
                "score": 15
              },
              {
                "value": "obligatorisk",
                "score": 15
              },
              {
                "value": "ferieøyene",
                "score": 15
              },
              {
                "value": "nydelig",
                "score": 15
              },
              {
                "value": "badeengler",
                "score": 15
              },
              {
                "value": "kjennetegnet",
                "score": 15
              },
              {
                "value": "praktfulle",
                "score": 15
              },
              {
                "value": "landskapet",
                "score": 15
              },
              {
                "value": "stilig",
                "score": 15
              },
              {
                "value": "grønn",
                "score": 15
              }
            ]
          }
        ],
        "properties": {
          "image": "https://www.vg.no/vgc/hyperion/img/logo.png",
          "publishedTime": "1998-07-01T22:00Z",
          "description": "En reise på egen hånd mellom de greske øyer gir større frihet og flere opplevelser enn en pakketur. I tillegg er det en enkel, rimelig og behagelig form for ferie, som slett ikke er forbeholdt ungdommer.",
          "type": "article",
          "title": "10 greske hopp"
        },
        "publishedTime": "1998-07-01T22:00:00Z",
        "hash": 10116837
      },
      "score": 0.7095990809939174
    },
    {
      "contentProfileRecord": {
        "@c": ".ContentProfileRecord",
        "key": "www.fremover.no/gleder-seg-over-sydentilbud-fra-evenes/s/5-17-1055444",
        "updateTime": "2023-08-07T14:09:46.191Z",
        "expiryTime": "2023-08-14T14:09:46.191Z",
        "dataSource": "ADNUNTIUS",
        "network": {
          "id": "try_opt",
          "tagId": "b7462",
          "name": "C ALT",
          "url": "/api/v1/networks/try_opt"
        },
        "language": "no",
        "keyValues": [
          {
            "key": "keywords",
            "values": [
              {
                "value": "pakkereiser",
                "score": 100
              },
              {
                "value": "gjennom",
                "score": 100
              },
              {
                "value": "pakkereiseloven",
                "score": 100
              },
              {
                "value": "reisende",
                "score": 100
              },
              {
                "value": "pakkereisene",
                "score": 100
              },
              {
                "value": "dag",
                "score": 100
              },
              {
                "value": "ønsker",
                "score": 100
              },
              {
                "value": "evenes",
                "score": 100
              },
              {
                "value": "lufthavn",
                "score": 100
              },
              {
                "value": "kreta",
                "score": 100
              },
              {
                "value": "får",
                "score": 100
              },
              {
                "value": "rivera",
                "score": 100
              },
              {
                "value": "tillegg",
                "score": 100
              },
              {
                "value": "gleder",
                "score": 100
              },
              {
                "value": "populært",
                "score": 100
              },
              {
                "value": "reise",
                "score": 100
              },
              {
                "value": "gundersen",
                "score": 100
              },
              {
                "value": "sommer",
                "score": 100
              },
              {
                "value": "apollo",
                "score": 100
              },
              {
                "value": "monica",
                "score": 100
              },
              {
                "value": "s",
                "score": 100
              },
              {
                "value": "direkte",
                "score": 100
              },
              {
                "value": "sier",
                "score": 100
              },
              {
                "value": "ankerstrøm",
                "score": 100
              },
              {
                "value": "dagene",
                "score": 100
              },
              {
                "value": "sydentilbud",
                "score": 100
              }
            ]
          },
          {
            "key": "categories",
            "values": []
          },
          {
            "key": "words",
            "values": [
              {
                "value": "rutefly",
                "score": 15
              },
              {
                "value": "flyr",
                "score": 15
              },
              {
                "value": "reiseliv",
                "score": 15
              },
              {
                "value": "etablert",
                "score": 15
              },
              {
                "value": "mer",
                "score": 15
              },
              {
                "value": "godt",
                "score": 15
              },
              {
                "value": "tilbud",
                "score": 15
              },
              {
                "value": "albania",
                "score": 15
              },
              {
                "value": "charterreisen",
                "score": 15
              },
              {
                "value": "ulike",
                "score": 15
              },
              {
                "value": "forklarer",
                "score": 15
              },
              {
                "value": "flere",
                "score": 15
              },
              {
                "value": "unik",
                "score": 15
              },
              {
                "value": "feriebyer",
                "score": 15
              },
              {
                "value": "lokalt",
                "score": 15
              },
              {
                "value": "destinasjon",
                "score": 15
              },
              {
                "value": "solhungrige",
                "score": 15
              },
              {
                "value": "bulgaria",
                "score": 15
              },
              {
                "value": "uke",
                "score": 15
              },
              {
                "value": "oslo",
                "score": 15
              },
              {
                "value": "platanias",
                "score": 15
              },
              {
                "value": "lanserer",
                "score": 15
              },
              {
                "value": "avreisedager",
                "score": 15
              },
              {
                "value": "fleksibilitet",
                "score": 15
              },
              {
                "value": "malaga",
                "score": 15
              },
              {
                "value": "bredere",
                "score": 15
              },
              {
                "value": "mellomlanding",
                "score": 15
              },
              {
                "value": "pressemelding",
                "score": 15
              },
              {
                "value": "reiselengder",
                "score": 15
              },
              {
                "value": "muligheter",
                "score": 15
              },
              {
                "value": "narvik",
                "score": 15
              },
              {
                "value": "avreiser",
                "score": 15
              },
              {
                "value": "herfra",
                "score": 15
              },
              {
                "value": "sesongen",
                "score": 15
              },
              {
                "value": "chania",
                "score": 15
              },
              {
                "value": "svært",
                "score": 15
              },
              {
                "value": "populære",
                "score": 15
              },
              {
                "value": "tradisjonelle",
                "score": 15
              },
              {
                "value": "forretningsutvikling",
                "score": 15
              },
              {
                "value": "direkteruter",
                "score": 15
              },
              {
                "value": "hele",
                "score": 15
              },
              {
                "value": "fylle",
                "score": 15
              },
              {
                "value": "deriblant",
                "score": 15
              },
              {
                "value": "bestiller",
                "score": 15
              },
              {
                "value": "selvfølgelig",
                "score": 15
              },
              {
                "value": "reisemålet",
                "score": 15
              },
              {
                "value": "administrasjon",
                "score": 15
              },
              {
                "value": "apostoli",
                "score": 15
              },
              {
                "value": "helt",
                "score": 15
              },
              {
                "value": "uansett",
                "score": 15
              },
              {
                "value": "innebærer",
                "score": 15
              },
              {
                "value": "eneste",
                "score": 15
              },
              {
                "value": "stadig",
                "score": 15
              },
              {
                "value": "spår",
                "score": 15
              },
              {
                "value": "vestkyst",
                "score": 15
              },
              {
                "value": "agii",
                "score": 15
              },
              {
                "value": "samlet",
                "score": 15
              },
              {
                "value": "beskyttet",
                "score": 15
              },
              {
                "value": "leder",
                "score": 15
              },
              {
                "value": "greske",
                "score": 15
              },
              {
                "value": "uken",
                "score": 15
              },
              {
                "value": "agia",
                "score": 15
              },
              {
                "value": "marina",
                "score": 15
              },
              {
                "value": "flyreisen",
                "score": 15
              },
              {
                "value": "harstad",
                "score": 15
              },
              {
                "value": "satser",
                "score": 15
              },
              {
                "value": "alternativ",
                "score": 15
              },
              {
                "value": "oktober",
                "score": 15
              },
              {
                "value": "øyas",
                "score": 15
              },
              {
                "value": "åpner",
                "score": 15
              },
              {
                "value": "beatriz",
                "score": 15
              },
              {
                "value": "nordlendinger",
                "score": 15
              },
              {
                "value": "fornøyd",
                "score": 15
              },
              {
                "value": "velge",
                "score": 15
              },
              {
                "value": "alt",
                "score": 15
              },
              {
                "value": "gjengjeld",
                "score": 15
              },
              {
                "value": "totalt",
                "score": 15
              },
              {
                "value": "setter",
                "score": 15
              },
              {
                "value": "ferieopplevelse",
                "score": 15
              },
              {
                "value": "april",
                "score": 15
              },
              {
                "value": "ferieøya",
                "score": 15
              },
              {
                "value": "imellom",
                "score": 15
              },
              {
                "value": "alltid",
                "score": 15
              },
              {
                "value": "flott",
                "score": 15
              },
              {
                "value": "rethymnonkysten",
                "score": 15
              },
              {
                "value": "videre",
                "score": 15
              },
              {
                "value": "kommunikasjonssjef",
                "score": 15
              },
              {
                "value": "kolymbari",
                "score": 15
              }
            ]
          }
        ],
        "properties": {
          "image": "https://g.api.no/obscura/API/dynamic/r1/ece5/tr_2000_2000_s_f/1672777552000/frem/2023/1/3/21/Eiendommer%2Bcollage%2B%25282%2529.jpg?chk=CA3DA7",
          "publishedTime": "2023-01-04T08:11:10Z",
          "description": "I sommer kan du reise direkte fra Evenes til den greske ferieøya Kreta.",
          "type": "article",
          "title": "Gleder seg over sydentilbud fra Evenes"
        },
        "publishedTime": "2023-01-04T08:11:10Z",
        "hash": 5254966
      },
      "score": 0.700334413747304
    },
    {
      "contentProfileRecord": {
        "@c": ".ContentProfileRecord",
        "key": "www.aftonbladet.se/resa/a/L04GzQ/guide-greklands-oar",
        "updateTime": "2023-08-08T09:28:29.572Z",
        "expiryTime": "2023-08-15T09:28:29.572Z",
        "dataSource": "ADNUNTIUS",
        "network": {
          "id": "try_opt",
          "tagId": "b7462",
          "name": "C ALT",
          "url": "/api/v1/networks/try_opt"
        },
        "language": "sv",
        "keyValues": [
          {
            "key": "keywords",
            "values": [
              {
                "value": "a",
                "score": 100
              },
              {
                "value": "oar",
                "score": 100
              },
              {
                "value": "resa",
                "score": 100
              },
              {
                "value": "l04gzq",
                "score": 100
              },
              {
                "value": "greklands",
                "score": 100
              },
              {
                "value": "guide",
                "score": 100
              }
            ]
          },
          {
            "key": "categories",
            "values": []
          },
          {
            "key": "words",
            "values": [
              {
                "value": "cirka",
                "score": 15
              },
              {
                "value": "klicka",
                "score": 15
              },
              {
                "value": "euro",
                "score": 15
              },
              {
                "value": "grekiska",
                "score": 15
              },
              {
                "value": "öar",
                "score": 15
              },
              {
                "value": "9,30",
                "score": 15
              },
              {
                "value": "dagsaktuell",
                "score": 15
              },
              {
                "value": "miljoner",
                "score": 15
              },
              {
                "value": "timme",
                "score": 15
              },
              {
                "value": "grekland",
                "score": 15
              },
              {
                "value": "kurs",
                "score": 15
              }
            ]
          }
        ],
        "properties": {
          "image": "https://images.aftonbladet-cdn.se/v2/images/e6600a9a-c0b2-422e-a3f6-c2162519b082?fit=crop&format=auto&h=157&q=50&w=300&s=2d0f9762d7b58e66ee9de730b756a892bdd3fb88",
          "publishedTime": "2007-07-10T11:49Z",
          "description": "Land: Grekland.",
          "type": "article",
          "title": "Guide/ Greklands öar"
        },
        "publishedTime": "2007-07-10T11:49:00Z",
        "hash": 9065720
      },
      "score": 0.7002428818812071
    }
  ],
  "totalCount": 9
};*/
