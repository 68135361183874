/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import ngFileUpload from 'ng-file-upload';
import _ from 'lodash';
import {ApiConfig} from "../api/api";

const MODULE_NAME = "adn-shotter-helper";

angular.module(MODULE_NAME, [
  ngFileUpload
])

  .factory('adnShotter', function($http) {
    return {
      takeShot: function(id, url, bypassSave, headless) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnshotter', 'takeshot']), {
          params: _.assign({}, {format: 'json', concise: true, url: url, creativeId: id, bypassSave: bypassSave, headless: headless})
        }).then(_.iteratee('data'));
      }
    };
  });

export default MODULE_NAME;